// @flow
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  MenuItem,
  TextField,
} from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import SnackBar from "../../../components/SnackBar";
import { Formik } from "formik";
import Loading from "components/Loading/Loading";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import useIsMountedRef from "hooks/useIsMountedRef";
import { updateApplication } from "redux/slices/applications";
import { fetchAllSections } from "redux/slices/sectionSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  content: {
    padding: 0,
  },
}));

const Form = (props) => {
  const { id } = useParams();
  const { className, edit, data,status, ...rest } = props;
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const dispatch = useDispatch();
  const [formState, setFormState] = useState({});
  const [message, setAlertMessage] = useState("");
  const [severity, setAlertSeverity] = useState("success");
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  // const {sections} = useSelector((state) => state?.sections);
  const {updateStatus} = useSelector((state)=> state?.applications)
  // useEffect(() => {
  //   dispatch(fetchAllSections());
  // }, [dispatch]);

  const handleSnackbar = (type, msg) => {
    setOpen(true);
    setAlertMessage(msg);
    setAlertSeverity(type);
  };
  const error = useSelector((state) => state.applications.error);
  if ((status === "loading" && edit) || updateStatus === "loading") {
    return <Loading />;
  }
  return (
    <div>
      <Card {...rest} className={clsx(classes.root, className)}>
        <Formik
          initialValues={{
            status: edit ? data.status : "",
            firstName: edit ? data.firstName : "",
            lastName: edit ? data.lastName : "",
            email: edit ? data.email : "",
            // section: edit ? data.section : "",
            phoneNumber: edit ? data.phoneNumber : "",
          }}
          validationSchema={Yup.object().shape({
            status: Yup.string().required(),
            firstName: Yup.string().required(),
            lastName: Yup.string().required(),
            email: Yup.string()
              .email("Must be a valid email")
              .required(),
            phoneNumber: Yup.number().required(),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            console.log(values);
            try {
              if (isMountedRef.current) {
                setStatus({ success: true });
                setSubmitting(false);
                if (edit) {
                  values.id = id;
                  dispatch(updateApplication(values));
                  if (error) {
                    handleSnackbar("error", error);
                  } else {
                    console.log("success");
                    handleSnackbar(
                      "success",
                      "Application updated successfully"
                    );
                  }
                }
              }
            } catch (err) {
              if (isMountedRef.current) {
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
              }
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => {
            console.log({ values, errors, data, touched });
            return (
              <form
                autoComplete="off"
                noValidate
                onSubmit={handleSubmit}
                {...rest}
              >
                <CardHeader subheader="Application form" />
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={4} xs={12}>
                      <TextField
                        select
                        variant="outlined"
                        label={edit ? "update status" : "choose Status"}
                        defaultValue={values?.status}
                        onChange={handleChange}
                        name="status"
                        fullWidth
                      >
                        <MenuItem value="Applied">Applied</MenuItem>
                        <MenuItem value="First Validation">
                          First Validation
                        </MenuItem>
                        <MenuItem value="Second Validation">
                          Second Validation
                        </MenuItem>
                        <MenuItem value="Accepted">Accepted</MenuItem>
                        <MenuItem value="Rejected">Rejected</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <TextField
                        mt={2}
                        fullWidth
                        label="Name"
                        name="firstName"
                        variant="outlined"
                        onChange={handleChange}
                        defaultValue={values.firstName}
                        error={errors?.firstName && touched.firstName}
                        helperText={
                          errors?.firstName && touched.firstName
                            ? errors?.firstName
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <TextField
                        fullWidth
                        label="Lastname"
                        defaultValue={values.lastName}
                        onChange={handleChange}
                        error={errors?.lastName && touched.lastName}
                        helperText={
                          errors?.lastName && touched.lastName
                            ? errors?.lastName
                            : ""
                        }
                        name="lastName"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <TextField
                        fullWidth
                        label="Phone Number"
                        defaultValue={values?.phoneNumber}
                        name="phoneNumber"
                        onChange={handleChange}
                        variant="outlined"
                        type="number"
                        error={errors?.phoneNumber && touched.phoneNumber}
                        helperText={
                          errors?.phoneNumber && touched.phoneNumber
                            ? errors?.phoneNumber
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <TextField
                        fullWidth
                        label="email"
                        defaultValue={values?.email}
                        onChange={handleChange}
                        error={errors?.email && touched.email}
                        helperText={
                          errors?.email && touched.email ? errors?.email : ""
                        }
                        name="email"
                        variant="outlined"
                        type="email"
                      />
                    </Grid>
                    {/* <Grid item md={4} xs={12}>
                      <TextField
                        select
                        type={"text"}
                        name="section"
                        variant="outlined"
                        label={"update Section"}
                        defaultValue={values?.section}
                        onChange={handleChange}
                        fullWidth
                        error={errors?.section && touched.section}
                        helperText={
                          errors?.section && touched.section
                            ? errors?.section
                            : ""
                        }
                      >
                        {sections?.map((el) => (
                          <MenuItem value={el.name}>{el.name}</MenuItem>
                        ))}
                      </TextField>
                    </Grid>  */}
                  </Grid>
                </CardContent>
                <Divider />
                <CardActions>
                  <Button color="primary" variant="contained" type="submit">
                    Save details
                  </Button>
                </CardActions>
              </form>
            );
          }}
        </Formik>
      </Card>
      <SnackBar
        open={open}
        message={message}
        severity={severity}
        handleClose={handleClose}
      />
    </div>
  );
};

Form.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  handleCloseLoading: PropTypes.func,
  loading: PropTypes.bool,
};

export default Form;
