/* -- Headers & API_URL ------------------ */
import headers from "../helpers/headers";
import headersFormData from "../helpers/headersFormData";
import mapValuesToFormData from "../helpers/mapValuesToFormData";
import { setItemFromStorage } from "./Storage";
import { getItemFromStorage } from "../utils/Storage.js";

const apiUrl = process.env.REACT_APP_API_URL;
const token = getItemFromStorage("token");

/* -- Default methods -------------------- */
const api_get = async (base_url,params) => {
  let newParams;
  if (params){
    const url=new URL(`${apiUrl}/${base_url}`)
    newParams=new URLSearchParams([...Array.from(url.searchParams.entries()),
      ...Object.entries(params)
    ]).toString()
  }

  return fetch(`${apiUrl}/${base_url}?${params?newParams:""}`, {
    method: "GET",
    headers,
  }).then((response) => {
    return response.json();
  });
};

const api_post = async (base_url, data, serialize_it) => {
  let seriali = serialize_it || false;
  return fetch(`${apiUrl}/${base_url}`, {
    method: "POST",
    headers : seriali === true ? headersFormData : headers,
         body: seriali === true ? mapValuesToFormData(data) : JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

const api_put_files = async (base_url, data, serialize_it) => {
  let seriali = serialize_it || false;
  return fetch(`${apiUrl}/${base_url}`, {
    method: "PUT",
    headers : seriali === true ? headersFormData : headers,
         body: seriali === true ? mapValuesToFormData(data) : JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

const api_put = async (base_url, data) => {
  return fetch(`${apiUrl}/${base_url}`, {
    method: "PUT",
    headers,
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
};

const api_delete = async (base_url) => {
  return fetch(`${apiUrl}/${base_url}`, {
    method: "DELETE",
    headers,
  }).then((response) => {
    return response.json();
  });
};

const login = async (credentials) => {
  return fetch(`${apiUrl}/api/v1/users/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  }).then(async (response) => {
    const json = await response.json();
    if (response.ok) {
      await setItemFromStorage("token", json.payload.token);
    } else {
      throw new Error(json.message);
    }
  });
};

/* -- Export methods --------------------- */
export {
  api_get,
  api_post,
  api_put,
  api_delete,
  api_put_files,
  login,
};
