// @flow
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useHistory, useParams } from "react-router-dom";
import SnackBar from "../../../components/SnackBar";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import LoadingApi from "components/LoadingApi/LoadingApi";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { createOffer, updateOffer } from "redux/slices/offers";
import useIsMountedRef from "hooks/useIsMountedRef";
import Loading from "components/Loading/Loading";
import { fetchAllSections } from "redux/slices/sectionSlice";
import CheckBox from "@material-ui/icons/CheckBox";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    overflow: "visible",
  },
  mtop: {
    marginTop: 24,
  },

  mtop0: {
    marginTop: 0,
  },
  content: {
    padding: 0,
  },
  uploadedFiles: {
    marginTop: 10,
  },
}));
// const modules = {
//   toolbar: [
//     [{ header: [1, 2, false] }],
//     ["bold", "italic", "underline", "strike", "blockquote"],
//     [
//       { list: "ordered" },
//       { list: "bullet" },
//       { indent: "-1" },
//       { indent: "+1" },
//     ],
//     ["link", "image"],
//     ["clean"],
//   ],
// };
// const formats = [
//   "header",
//   "bold",
//   "italic",
//   "underline",
//   "strike",
//   "blockquote",
//   "list",
//   "bullet",
//   "indent",
//   "link",
//   "image",
// ];

const Form = (props) => {
  const { id } = useParams();
  const { className, edit, data, offerStatus, ...rest } = props;
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const { updateError, updateStatus } = useSelector((state) => state.offers);

  const history = useHistory();
  const { sections } = useSelector((state) => state?.sections);
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();
  today = mm + "/" + dd + "/" + yyyy;

  const [message, setAlertMessage] = useState("");
  const [severity, setAlertSeverity] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleSnackbar = (type, msg) => {
    setOpen(true);
    setAlertMessage(msg);
    setAlertSeverity(type);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseLoading = () => {
    setLoading(false);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAllSections({ limit: "total" }));
  }, [dispatch]);

  if ((offerStatus === "loading" && edit) || updateStatus === "loading") {
    return <Loading />;
  }

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Formik
        initialValues={{
          Name: edit ? data?.Name : "",
          vacancies: edit ? data?.vacancies : "",
          active: edit ? data?.active : false,
          desc: edit ? data?.desc : "",
          section: edit ? data.section?._id : "",
          startsAt: edit ? data?.startsAt?.split("T")[0] : "",
          ExpiresIn: edit ? data?.ExpiresIn?.split("T")[0] : "",
          location: edit ? data?.location : "",
        }}
        validationSchema={Yup.object().shape({
          Name: Yup.string()
            .required()
            .min(5, "min length is 5"),
          vacancies: Yup.number()
            .positive()
            .required(),
          active: Yup.boolean(),
          desc: Yup.string()
            .required()
            .min(5, "min length is 5"),
          section: Yup.string().required(),
          location: Yup.string().required(),
          startsAt: edit
            ? Yup.date()
                .max(
                  Yup.ref("ExpiresIn"),
                  "startAt must be less than ExpiresIn"
                )
                .required()
            : Yup.date()
                .min(new Date(), "startAt must be greater than today")
                .max(
                  Yup.ref("ExpiresIn"),
                  "startAt must be less than ExpiresIn"
                )
                .required(),
          ExpiresIn: Yup.date().required(),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            if (isMountedRef.current) {
              setStatus({ success: true });
              setSubmitting(false);
              if (edit) {
                values.id = id;
                dispatch(updateOffer(values)).then((res) => {
                  if (res.meta.requestStatus === "rejected") {
                    handleSnackbar("error", res.error.message);
                  } else {
                    handleSnackbar("success", "Offer updated successfully");
                  }
                });
              }
              if (!edit) {
                dispatch(createOffer(values));
                if (updateError) {
                  handleSnackbar("error", updateError);
                } else {
                  handleSnackbar("success", "Offer created successfully");
                  setTimeout(() => {
                    history.push("/contents/offers");
                  }, 4000);
                }
              }
            }
          } catch (err) {
            if (isMountedRef.current) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => {
          return (
            <>
              <form
                autoComplete="off"
                noValidate
                onSubmit={handleSubmit}
                {...rest}
              >
                {/* big grid */}
                <Grid container spacing={3}>
                  {/* grid One  */}
                  <Grid item xs={12} lg={7}>
                    <Card className={classes.root}>
                      <CardContent>
                        <TextField
                          fullWidth
                          label="Name"
                          name="Name"
                          onChange={handleChange}
                          required={!edit}
                          defaultValue={values?.Name}
                          error={errors?.Name && touched.Name}
                          helperText={
                            errors?.Name && touched.Name ? errors?.Name : ""
                          }
                          variant="outlined"
                          size="small"
                        />
                        <Box mt={2} mb={2}>
                          <Typography variant="subtitle2" color="textSecondary">
                            Description
                          </Typography>
                        </Box>
                        <TextField
                          fullWidth
                          name="desc"
                          onChange={handleChange}
                          label="description"
                          multiline
                          error={errors?.desc && touched.desc}
                          helperText={
                            errors?.desc && touched.desc ? errors?.desc : ""
                          }
                          rows={22}
                          defaultValue={values?.desc}
                          variant="outlined"
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} lg={5}>
                    <Box mt={0}>
                      <Card className={classes.root}>
                        <CardHeader title="Status" />
                        <Divider className={classes.mtop0} />
                        <CardContent className={classes.mtop0}>
                          <Box mt={0}>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    defaultChecked={values.active}
                                    name="active"
                                    onChange={handleChange}
                                  />
                                }
                                label="Active"
                              />
                            </FormGroup>
                          </Box>
                          <Box mt={0}>
                            <TextField
                              fullWidth
                              type="number"
                              label="vacancies"
                              name="vacancies"
                              onChange={handleChange}
                              error={errors?.vacancies && touched.vacancies}
                              helperText={
                                errors?.vacancies && touched.vacancies
                                  ? errors?.vacancies
                                  : ""
                              }
                              required
                              defaultValue={values.vacancies}
                              variant="outlined"
                            />
                          </Box>
                          <Box mt={2}>
                            <TextField
                              fullWidth
                              type="text"
                              name="location"
                              onChange={handleChange}
                              label="Location"
                              placeholder="Location"
                              error={errors?.location && touched.location}
                              helperText={
                                errors?.location && touched.location
                                  ? errors?.location
                                  : ""
                              }
                              required
                              defaultValue={values.location}
                              variant="outlined"
                            />
                          </Box>
                          <Box mt={2}>
                            <FormControl variant="outlined" fullWidth>
                              <TextField
                                select
                                variant="outlined"
                                error={errors?.section && touched.section}
                                helperText={errors?.section}
                                onChange={handleChange}
                                defaultValue={values.section}
                                name="section"
                                label="Section"
                              >
                                {sections &&
                                  sections?.map((el) => (
                                    <MenuItem value={el._id}>
                                      {el.name}
                                    </MenuItem>
                                  ))}
                              </TextField>
                            </FormControl>
                          </Box>
                        </CardContent>
                      </Card>
                    </Box>
                    <Box mt={2}>
                      <Card className={classes.root}>
                        <CardHeader title="Dates" />
                        <Divider className={classes.mtop0} />
                        <CardContent className={classes.mtop0}>
                          <Box mt={2}>
                            <TextField
                              fullWidth
                              label="startsAt"
                              name="startsAt"
                              type="date"
                              variant="outlined"
                              onChange={handleChange}
                              defaultValue={values?.startsAt}
                              error={errors?.startsAt && touched.startsAt}
                              helperText={
                                errors.startsAt && touched.startsAt
                                  ? errors?.startsAt
                                  : ""
                              }
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Box>
                          <Box mt={2}>
                            <TextField
                              fullWidth
                              label="ExpiresIn"
                              name="ExpiresIn"
                              type="date"
                              variant="outlined"
                              onChange={handleChange}
                              error={errors?.ExpiresIn && touched.ExpiresIn}
                              helperText={
                                errors?.ExpiresIn && touched.ExpiresIn
                                  ? errors?.ExpiresIn
                                  : ""
                              }
                              defaultValue={values?.ExpiresIn}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Box>
                        </CardContent>
                      </Card>
                    </Box>
                  </Grid>
                </Grid>
                <Box mt={3}>
                  <Button color="primary" variant="contained" type="submit">
                    Save details
                  </Button>
                </Box>
              </form>
              <SnackBar
                open={open}
                message={message}
                severity={severity}
                handleClose={handleClose}
              />
              <LoadingApi open={loading} onClick={handleCloseLoading} />
            </>
          );
        }}
      </Formik>
    </div>
  );
};

Form.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  handleCloseLoading: PropTypes.func,
  loading: PropTypes.bool,
};

export default Form;
