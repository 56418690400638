import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
} from '@material-ui/core';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Chip from '@material-ui/core/Chip';
import moment from 'moment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
    zIndex: 0
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  orange: {
    color: 'red',
  },
  purple: {
    color: 'green',
  },
  actions: {
    justifyContent: 'flex-end',
  },
  hidden: {
    display: 'none',
  },
  listItem: {
    padding: 0,
  },
  customTableContainer: {
    overflowX: "initial"
  },
  CardButtonDelete: {
    background: 'white',
    fontWeight: 'bold',
    fontFamily: theme.typography.fontFamily,
    fontSize: "14px",
    paddingBottom: "0px",
  }
}));

/**
 * Returns an enhanced table
 *
 * options:
 * sortable: true
 * available column types:
 * chip: color[default, primary]
 * boolean: color[default, primary]
 * date: color[default, primary]
 * simpleList: field
 * switch: -soon-
 */
const CustomTable = (props) => {
  const classes = useStyles();
  const {
    data,
    columns,
    sort,
    handleSortChange,
    ActionComponent,
    tableService,
    pageLink,
    initializeData,
    rowsPerPage,
    page
  } = props;

  const [selected, setSelected] = useState([]);
  const handleSelectAll = (event) => {
    let selected;
    if (event.target.checked) {
      selected = data?.map((row) => row.id);
    } else {
      selected = [];
    }
    setSelected(selected);
  };
  const handleSelectOne = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };
  const accessor = (obj, is, value) => {
    if (typeof is == 'string') {
      return accessor(obj, is.split('.'), value);
    } else if (is.length === 1 && value !== undefined) {
      return obj[is[0]] = value;
    } else if (is.length === 0) {
      return obj;
    } else {
      if (obj[is[0]]) {
        return accessor(obj[is[0]], is.slice(1), value);
      }
      return '--';
    }
  };
  const chipType = (col, value) => {
    return <Chip
      size="small"
      color={(value === 'Yes') ? 'secondary' : 'primary'}
      label={value}
    />;
  };
  const booleanType = (col, value) => {
    const v = Number(value) ? 'Yes' : 'No';
    return chipType(col, v);
  };
  const dateType = (col, value) => {
    const v = moment(value).isValid() ?
      moment(value).format('DD/MM/YYYY').toString() :
      '* * * * *';
    return chipType(col, v);
  };
  const dateTimeType = (col, value) => {
    const v = moment(value).isValid() ?
      moment(value, 'YYYY/MM/DD HH:mm').format('DD/MM/YYYY HH:mm').toString() :
      '* * * * *';
    return chipType(col, v);
  };
  const simpleListType = (col, value) => {
    return <List>
      {value?.map((v) => (
        <ListItem key={v.id}>
          <Chip
            size="small"
            color="secondary"
            //value without column name
            label={typeof v === 'string' ? v : v[col.field]}
          />
        </ListItem>
      ))}
    </List>;
  };
  const statusHandleColor = (col, value) => {
    let Bgcolor;
    const colorHandle = (val) => {
      switch (val) {
        case 'Applied':
          return Bgcolor = "#6464b1";
        case 'First Validation':
          return Bgcolor = "orange";
        case 'Second Validation':
          return Bgcolor = "brown";
        case 'Accepted':
          return Bgcolor = "#82af82";
        case 'Rejected':
          return Bgcolor = "red";
        default:
          return Bgcolor = "blue";
      }
    }
    colorHandle(value)
    return <Chip
      size="small"
      style={{ backgroundColor: Bgcolor, color: "white" }}
      label={value}
    />
  };
  const fieldValue = (col, value) => {
    return value !== null && value !== 'NA' ? value : ' '
  }
  const renderType = (item, col) => {
    const value = accessor(item, col.accessor);
    switch (col.type) {
      case 'chip':
        return chipType(col, value);
      case 'boolean':
        return booleanType(col, value);
      case 'date':
        return dateType(col, value);
      case 'dateTime':
        return dateTimeType(col, value)
      case 'simpleList':
        return simpleListType(col, value);
      case 'status':
        return statusHandleColor(col, value);
      default:
        return value;
    }
  };
  
 
  return (
    <div>
      <div style={{ position: 'relative', zIndex: '0' }} className={classes.root}>
        <TableContainer style={{ maxHeight: '700px' }}>
          <Table stickyHeader aria-label="sticky table"  >
            <TableHead  >
              <TableRow >
                <TableCell padding="checkbox"></TableCell>
                {columns.map((col) => (
                  col.show && <TableCell key={col.accessor}>
                    {col.sortable ? (
                      <TableSortLabel
                        active={sort.accessor === col.label}
                        direction={sort.accessor === col.label ?
                          sort.order : 'asc'}
                        onClick={() => handleSortChange(col.label)}
                      >
                        {col.label}
                      </TableSortLabel>) : (
                      <span>{col.label}</span>
                    )}
                  </TableCell>
                )
                )}
                {ActionComponent && <TableCell>Action</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {rowsPerPage > 0 ?
              data?.map((item) => (
                <TableRow
                  hover
                  key={item._id}
                >
                  {
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selected.indexOf(item._id) !== -1}
                        color="primary"
                        onChange={(event) => handleSelectOne(event, item._id)}
                        value="true"
                      />
                    </TableCell>
                  }
                  {columns.map((col) =>
                    col.show && <TableCell
                      key={`${col.accessor}-${item.id}`}
                      style={{ width: col.width }}
                    >
                      {renderType(item, col)}
                    </TableCell>
                  )}
                  {ActionComponent && <TableCell style={{ width: '6%' }}>
                    <div style={{ 'display': 'flex', 'justifyContent': 'space-between', 'alignItems': 'center' }}>
                      <ActionComponent
                        itemId={item._id}
                        tableService={tableService}
                        pageLink={pageLink}
                        initializeData={initializeData}
                        rowsPerPage={rowsPerPage}
                        page={page}
                      />
                    </div>
                  </TableCell>}
                </TableRow>
              )):
              <></>
            }
            </TableBody>
          </Table>
        </TableContainer >

      </div>
      <div>
      </div>
    </div>
  );
};

CustomTable.propTypes = {
  data: PropTypes.object,
  columns: PropTypes.array,
  sort: PropTypes.object,
  handleSortChange: PropTypes.func,
  ActionComponent: PropTypes.elementType,
};

export default CustomTable;
