import React from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  TableContainer,
} from "@material-ui/core";
import Label from "../../../components/Label";
const useStyles = makeStyles((theme) => ({
  root: {},
  scroll: {
    maxHeight: 435,
    overflowY: "scroll",
  },
}));
const LatestApplicants = ({
  className,
  OfferApplications,
  slices,
  ...rest
}) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title="Applications to this Offer" />
      <Divider />
      <TableContainer className={clsx(classes.scroll, className)}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Section</TableCell>
              <TableCell>City</TableCell>
              <TableCell>status</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Email</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {OfferApplications?.length ? (
              OfferApplications.map((app, index) => (
                <TableRow
                  hover
                  style={{ cursor: "pointer" }}
                  key={app?._id}
                  onClick={() =>
                    history.push(`/contents/applications/view/${app?._id}`)
                  }
                >
                  <TableCell>{app?.firstName}</TableCell>
                  <TableCell>
                    <Label color={app.section === "Bac" ? "success" : "error"}>
                      {app.level}
                    </Label>
                  </TableCell>
                  <TableCell>{app?.city ? app?.city : "---"}</TableCell>
                  <TableCell>
                    <Label
                      color={app.status === "rejected" ? "error" : "success"}
                    >
                      {app.status}
                    </Label>
                  </TableCell>
                  <TableCell>{app?.phoneNumber}</TableCell>
                  <TableCell>{app?.email}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell>No applications yet</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};
LatestApplicants.propTypes = {
  className: PropTypes.string,
  slices: PropTypes.object.isRequired,
};
export default LatestApplicants;
