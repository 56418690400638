import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api_delete, api_get,  api_post,  api_put, api_put_files } from "utils/Api";
import myAxiosInstance from "utils/myAxios";

const initialState = {
  userSelectedTable:"",
  users: [],
  meta:[],
  status: "idle",
  error: null,
  newUser:[],
  statusAddNewUser:"init",
  errorAddNewUser:null,
  updatedUserPass:[],
  statusUpdateUserPassword:"init",
  errorUpdateUserPassword:null,
  updatedUser:"",
  statusUpdatedUser:"init",
  errorUpdateUser:null,
  deletedUser:[],
  statusDeletedUser:"init",
  errorDeletedUser:null,
  user:"",
  errorUser:null,
  statusSelectedUser:"init",
  filteredValue:[],
  sectionLevels:"section levels loading !",
  SelectedSection:false,
  statusSendEmailUpdateToken:"init",
  errorSendEmailUpdateToken:null,
  emailUpdateToken:"",
  statusEditProfilePicture:"init",
  errorEditProfilePicture:null,
  currentUserData:[]
};

export const fetchAllUsers = createAsyncThunk("users/fetchAllUsers", async (queryParams) => {
  let data;
     if (queryParams){
        if(Object.keys(queryParams).includes("createdAt") ){
          const values=queryParams["createdAt"]
          delete queryParams.createdAt
          queryParams['createdAt[gte]']=values[0]+"T00:00:00.000Z"
          let newValue1=(Number(values[1].split("-")[2])+1).toString()
          newValue1=values[1].split("-")[0]+"-"+values[1].split("-")[1]+"-"+newValue1
          queryParams['createdAt[lte]']=newValue1+"T23:59:59.999Z"
        }
     }
    try {
      const response = await api_get(`api/v1/users/`, queryParams);
      data = await response;
      if (response.status ==="success") {
      return data;
      }
      throw new Error(response.message);
    } 
    catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
});

export const fetchOneUser= createAsyncThunk("users/fetchOneUser", async (userId) => {
  let id=userId
  let data;
    try {
      const response = await api_get(`api/v1/users/${id}`, );
      data = await response.data;
      if (response.status ==="success") {
      return data;
      }
      throw new Error(response.message);
    } 
    catch (err) {
      console.log("err in fetchOneUser",err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
});

export const editUser=createAsyncThunk("users/editUser",async(body,thunkAPI)=>{ 
const Data=body
const id=Data?.id
delete Data.id
   let data;
   try {
     const response = await api_put(`api/v1/users/${id}/update`, Data);
     data = await response.data;
      if (response.status ==="success") {
      thunkAPI.dispatch(fetchOneUser(id))
      thunkAPI.dispatch(fetchAllUsers());
       return data;
      }
    throw new Error(response.statusText);
  }
   catch (err) {
    console.log(err)
    return Promise.reject(err.message ? err.message : data?.message);
  }

})

export const editProfilePicture=createAsyncThunk("users/editProfilePicture",async(body,thunkAPI)=>{ 
  const Data=body
  const id=Data?.id
  delete Data.id
     let data;
     try {
       const response = api_put_files(`api/v1/users/${id}/updateProfilePicture`,Data,true)
        data = await response.data;
        if (response.status = 200) {
        thunkAPI.dispatch(fetchOneUser(id))
         return data;
        }
      throw new Error(response.message);
     }
     catch (err) {
      console.log(err)
      return Promise.reject(err.message ? err.message : data?.message);
    }
  
})
  
export const addNewUser=createAsyncThunk("users/addNewUser",async(body,thunkAPI)=>{
  let Data=body
  let data; 
  const response=await myAxiosInstance.post(`api/v1/users/addNewUser`,Data)  
  try{ 
    data=await response.data;
    if(response.status=200){
      thunkAPI.dispatch(fetchAllUsers())
      return data
    }
    throw new Error(response.message);
  }
  catch(err){
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
})

export const deleteOneUser=createAsyncThunk("users/deleteUser",async(body,thunkAPI)=>{
  const id=body.id
  delete body.id 
  let queries=body
  let data;

     try {
       const response = await api_delete(`api/v1/users/${id}`);
       data = await response.data;
        if (response.status ==="success") {
         thunkAPI.dispatch(fetchAllUsers(queries));
         return data;
        }
      throw new Error(response.message);
    }
     catch (err) {
      console.log(err)
      return Promise.reject(err.message ? err.message : data?.message);
    }
})

export const updateUserPassword=createAsyncThunk("users/updatePasswordUser",async(body,thunkAPI)=>{ 
  const Data=body
  const id=Data?.id
  delete Data.id
    let data;
     try {
       const response = await api_put(`api/v1/users/${id}/updatePassword`,Data);
       data = await response.data;
        if (response.status ==="success") {
         thunkAPI.dispatch(fetchOneUser(id));
         return data;
        }
      throw new Error(response.message);
    }
     catch (err) {
      console.log(err)
      return Promise.reject(err.message ? err.message : data?.message);
    }
  
})
  
export const sendEmailUpdateToken=createAsyncThunk("users/sendEmailUpdateToken",async(body,thunkAPI)=>{
  const Data=body
  const id=Data?.id
  delete Data.id
     let data;
     try {
       const response = await api_post(`api/v1/users/sendEmailUpdateToken`,Data);
       data = await response.data;
        if (response.status ==="success") {
         return data;
        }
      throw new Error(response.message);
    }
     catch (err) {
      console.log(err)
      return Promise.reject(err.message ? err.message : data?.message);
    }
})

const slice = createSlice({
  name: "users",
  initialState,
  reducers: {
    changeStatusAddNewUser:(state,action)=>{
      state.statusAddNewUser="init"
      state.errorAddNewUser=null
      state.newUser=""
    },
    changeStatusUpdateUser:(state,action)=>{
      state.statusUpdatedUser="init"
      state.errorUpdateUser=null
      state.updatedUser=""
    },
    changeStatusUpdatePassword:(state,action)=>{
      state.statusUpdateUserPassword="init"
      state.errorUpdateUserPassword=null
      state.updatedUserPass=""
    },
    changeStatusDeletedUser:(state,action)=>{
      state.statusDeletedUser="init"
      state.errorDeletedUser=null
      state.deletedUser=""
    },
    changeStatusEmailUpdate:(state,action)=>{
      state.statusSendEmailUpdateToken="init"
      state.errorSendEmailUpdateToken=null
      state.emailUpdateToken=""
    },
    changeStatusProfilePicture:(state,action)=>{
      state.statusEditProfilePicture="init"
      state.errorEditProfilePicture=null
    },
    saveFilteredValues:(state,action)=>{
      state.filteredValue=action.payload
    },
    changeSectionLevels:(state,action)=>{
      state.sectionLevels=action.payload
    },
    saveSectionSelected:(state,action)=>{
      state.SelectedSection=action.payload
    },
    removeSelectedUserFromStore:(state,action)=>{
      state.user=""
      state.userSelectedTable=""
      state.statusSelectedUser="init"
      state.errorUser=null
    },
  },

  extraReducers: {
    [fetchAllUsers.pending]: (state) => {
      state.status = "loading";
    },
    [fetchAllUsers.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.users = action.payload.data;
      state.meta=action.payload.meta
    },
    [fetchAllUsers.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [fetchOneUser.pending]: (state) => {
      state.statusSelectedUser = "loading";
    },
    [fetchOneUser.fulfilled]: (state, action) => {
      state.statusSelectedUser = "succeeded";
      state.user = action.payload;
    },
    [fetchOneUser.rejected]: (state, action) => {
      state.statusSelectedUser = "failed";
      state.errorUser= action.payload;
    },
    [addNewUser.pending]: (state) => {
      state.statusAddNewUser = "loading";
    },
    [addNewUser.fulfilled]: (state, action) => {
      state.statusAddNewUser = "succeeded";
      state.newUser = action.payload;
    },
    [addNewUser.rejected]: (state, action) => {
      state.statusAddNewUser = "failed";
      state.errorAddNewUser = action.error.message;
    },
    [updateUserPassword.pending]: (state) => {
      state.statusUpdateUserPassword = "loading";
    },
    [updateUserPassword.fulfilled]: (state, action) => {
      state.statusUpdateUserPassword = "succeeded";
      state.updatedUserPass = action.payload;
    },
    [updateUserPassword.rejected]: (state, action) => {
      state.statusUpdateUserPassword = "failed";
      state.errorAddNewUser = action.payload;
    },
    [editUser.pending]: (state) => {
      state.statusUpdatedUser = "loading";
    },
    [editUser.fulfilled]: (state, action) => {
      state.statusUpdatedUser = "succeeded";
      state.updatedUser = action.payload;
    },
    [editUser.rejected]: (state, action) => {
      state.statusUpdatedUser = "failed";
      state.errorAddNewUser = action.payload;
    },
    [deleteOneUser.pending]: (state) => {
      state.statusDeletedUser = "loading";
    },
    [deleteOneUser.fulfilled]: (state, action) => {
      state.statusDeletedUser = "succeeded";
      state.deletedUser = action.payload;
    },
    [deleteOneUser.rejected]: (state, action) => {
      state.statusDeletedUser = "failed";
      state.errorDeletedUser = action.payload;
    },
    [sendEmailUpdateToken.pending]: (state) => {
      state.statusSendEmailUpdateToken = "loading";
    },
    [sendEmailUpdateToken.fulfilled]: (state, action) => {
      state.statusSendEmailUpdateToken = "succeeded";
      state.emailUpdateToken = action.payload;
    },
    [sendEmailUpdateToken.rejected]: (state, action) => {
      state.statusSendEmailUpdateToken = "failed";
      state.errorSendEmailUpdateToken = action.error.message;
    },
    [editProfilePicture.pending]: (state) => {
      state.statusEditProfilePicture = "loading";
    },
    [editProfilePicture.fulfilled]: (state, action) => {
      state.statusEditProfilePicture = "succeeded";
    },
    [editProfilePicture.rejected]: (state, action) => {
      state.statusEditProfilePicture = "failed";
      state.errorEditProfilePicture= action.payload;
    },
  },
});

export const reducer = slice.reducer;
export const { removeSelectedUserFromStore ,saveFilteredValues ,changeStatusAddNewUser,
             changeStatusDeletedUser,changeSectionLevels ,saveSectionSelected ,
             changeStatusUpdateUser,changeStatusProfilePicture ,changeStatusUpdatePassword,changeStatusEmailUpdate} = slice.actions;
export default slice;
