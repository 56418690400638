import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Grid, makeStyles } from "@material-ui/core";
import BasicsInfo from "./BasicsInfo";
import LatestApplicants from "./LatestApplicants";

const useStyles = makeStyles(() => ({
  root: {},
}));

const Details = ({ offer, className, OfferApplications, ...rest }) => {
  const classes = useStyles();
  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      spacing={3}
      {...rest}
    >
      <Grid item lg={4} md={6} xl={4} xs={12}>
        <BasicsInfo offer={offer} />
      </Grid>
      <Grid item lg={8} md={8} xl={8} xs={12}>
        <LatestApplicants OfferApplications={OfferApplications} />
      </Grid>
    </Grid>
  );
};

Details.propTypes = {
  className: PropTypes.string,
  chapter: PropTypes.object.isRequired,
};

export default Details;
