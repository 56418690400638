import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Form } from './components';
import SimpleToolbar from '../../components/Toolbar/SimpleToolbar';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOneApplication } from 'redux/slices/applications';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

const DivisionEdit = (props) => {
  const { Link, pageLink, title, ...rest } = props;
  const classes = useStyles();
  const { id } = useParams()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchOneApplication(id))
  }, [dispatch, id])

  const {oneApplication, status} = useSelector((state) => state.applications)
  return (
    <div className={classes.root}>
      <SimpleToolbar
        Link={Link}
        pageLink={'/contents/applications'}
        title={'Applications'} />
      <div className={classes.content}>
        <Form edit={true} data={oneApplication} status={status} />
      </div>
    </div>
  );
};

DivisionEdit.propTypes = {
  user: PropTypes.object,
};

export default DivisionEdit;
