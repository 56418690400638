import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import SnackBar from "../SnackBar";
import { deleteOneUser } from "redux/slices/usersSlice";
import { useDispatch } from "react-redux";
import { deleteOffer } from "redux/slices/offers";
import { deleteApplication } from "redux/slices/applications";
import { deleteOneSection } from "redux/slices/sectionSlice";
import { deleteOneSubject } from "redux/slices/subjectsSlice";
import { deleteOneLevel } from "redux/slices/levelSlice";

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
}));

const Delete = (props) => {
  const {
    className,
    handleDelete,
    open,
    itemId,
    service,
    pageLink,
    initializeData,
    page,
    rowsPerPage,
    ...rest
  } = props;
  const classes = useStyles();
  const [openAlert, setOpenAlert] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [severity, setAlertSeverity] = React.useState("success");
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpenAlert(false);
  };
  const deleteUser = () => {
    let query = { page: page + 1, limit: rowsPerPage, id: itemId };
    if (service === "offers") {
      dispatch(deleteOffer(query));
      handleDelete();
    }
    if (service === "applications") {
      dispatch(deleteApplication(query));
      handleDelete();
    }
    if (service === "user") {
      dispatch(deleteOneUser(query));
      handleDelete();
    }
    if (service === "section") {
      dispatch(deleteOneSection(query));
      handleDelete();
    }
    if (service === "subject") {
      dispatch(deleteOneSubject(query));
      handleDelete();
    }
    if (service === "level") {
      dispatch(deleteOneLevel(query));
      handleDelete();
    }
  };
  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Dialog
        open={open}
        onClose={handleDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure to continue deleting?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete} color="primary">
            Cancel
          </Button>
          <Button onClick={deleteUser} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <SnackBar
        open={openAlert}
        handleClose={handleClose}
        message={message}
        severity={severity}
      />
    </div>
  );
};

Delete.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  handleDelete: PropTypes.func,
  itemId: PropTypes.number,
};

export default Delete;
