// @flow
import React, { useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import clsx from "clsx";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import { useHistory, useParams } from "react-router-dom";
import Tab from "@material-ui/core/Tab";
import TabPanel from "@material-ui/lab/TabPanel";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { AsyncPaginate } from "react-select-async-paginate";
import theme from "../../../theme";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import SubscriptionsForm from "./SubscriptionsForm";
import PointsForm from "./PointsForm";
import SnackBar from "../../../components/SnackBar";
import PropTypes from "prop-types";
import { DatePicker, LocalizationProvider } from "@material-ui/pickers";
import momentAdapter from "@material-ui/pickers/adapter/moment";
import Moment from "moment";
import { Formik } from "formik";
import * as Yup from "yup";
import Loading from "components/Loading/Loading";
import useIsMountedRef from "hooks/useIsMountedRef";
import { Box } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewUser,
  editUser,
  updateUserPassword,
  changeStatusAddNewUser,
  changeStatusUpdateUser,
  fetchOneUser,
  changeStatusUpdatePassword,
  fetchAllUsers,
  sendEmailUpdateToken,
} from "redux/slices/usersSlice";
import { fetchAllSections } from "redux/slices/sectionSlice";
import { fetchAllLevels } from "redux/slices/levelSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
  },
  content: {
    padding: 0,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      minWidth: "10px",
    },
  },
}));

const Form = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const isMountedRef = useIsMountedRef();
  const classes = useStyles();
  const { className, edit, user, statusUser, ...rest } = props;

  const roles=[{"_id":"user","name":"user"},{"_id":"admin","name":"admin"}]
  const genders=[{"_id":"male","name":"male"},{"_id":"female","name":"female"}]
  
  const [values, setValues] = useState({
    name: "",
    lastName: "",
    email: null,
    phoneNumber: "",
    password: "",
    passwordConfirm: "",
  });
  const [message, setAlertMessage] = useState("");
  const [severity, setAlertSeverity] = useState("");
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState(1);
  const [points, setPoints] = useState(0);
  const [section, setSection] = useState("");
  const [maleChecked, setMaleChecked] = useState("");
  const [femaleChecked, setFemaleChecked] = useState("");
  const [userRoleChecked, setUserRoleChecked] = useState("");
  const [adminRoleChecked, setAdminRoleChecked] = useState("");

  const newUserId = useSelector((state) => state?.users?.newUser.id);
  const newUserRole = useSelector((state) => state?.users?.newUser.role);
 
  const testId=useSelector((state)=> state?.users?.user)
  const statusUpdatePassword = useSelector(
    (state) => state?.users?.statusUpdateUserPassword
  );
  const statusEditUser = useSelector(
    (state) => state?.users?.statusUpdatedUser
  );
  const statusAddUser = useSelector((state) => state?.users?.statusAddNewUser);

  useEffect(() => {
    if (newUserId) {
      dispatch(fetchOneUser(newUserId));
    }
  }, [newUserId]);

  const [getLevelsSection, setGetLevelsSection] = useState("");
  const [getSectionName, setGetSectionName] = useState("");
  const [sectionsName, setSectionsName] = useState("");
  const [levelsName, setLevelsName] = useState("");

  const history = useHistory();
  const errorAddUser = useSelector((state) => state?.users?.errorAddNewUser);

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  useEffect(() => {
    if (!edit && statusAddUser==="succeeded" && newUserRole.length>0){
      dispatch(changeStatusAddNewUser());
      setMaleChecked("");
      setFemaleChecked("");
      setUserRoleChecked("");
      setAdminRoleChecked("");
      if ( newUserRole === "user") {
        setTab(2);
        handleSnackBar("success", "User Created Successfully!");
      }
      if ( newUserRole === "admin") {
        handleSnackBar("success", "Admin Created Successfully!");
        setTimeout(() => {
          history.push(`/users/students/view/${newUserId}`);
        }, 1000);
      }
    }
  }, [!edit, statusAddUser, history, edit, newUserId]);

  useEffect(() => {
    if (!edit && errorAddUser && tab === 1) {
      handleSnackBar("error", errorAddUser);
    }
  }, [!edit, errorAddUser, tab === 1]);

  useEffect(() => {
    setValues(user);
  }, [user]);

  useEffect(() => {
    dispatch(fetchAllSections());
    dispatch(fetchAllLevels());
  }, [dispatch]);

  const { sections } = useSelector((state) => state?.sections);
  const { levels } = useSelector((state) => state?.levels);

  useEffect(() => {
    if (sections && levels) {
      setSectionsName(sections.map((section) => section.name));
      setLevelsName(levels.map((level) => level.name));
    }
  },[sections, levels]);

  const handleSnackBar = (type, msg) => {
    setOpen(true);
    setAlertSeverity(type);
    setAlertMessage(msg);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (
      statusEditUser === "succeeded" &&
      statusUpdatePassword !== "succeeded"
    ) {
      handleSnackBar("success", "User Edited Successfully");
      dispatch(changeStatusUpdateUser());
      setTimeout(() => {
        history.push(`/users/students/view/${testId._id}`);
      }, 1500);
    }

 }, [statusEditUser]);

  useEffect(() => {
    if (edit && statusUpdatePassword === "succeeded") {
      handleSnackBar("success", "Password Updated  Successfully!");
      dispatch(changeStatusUpdatePassword())
    }
  }, [edit, statusUpdatePassword]);

  if (edit && statusUser === "loading") {
    return <Loading />;
  }

  return (
    <div>
      <Card {...rest} className={clsx(classes.root, className)}>
        <TabContext value={tab}>
          <div className={classes.header}>
            <TabList
              scrollButtons="auto"
              variant="scrollable"
              className={classes.header}
              onChange={handleChangeTab}
              aria-label="simple tabs example"
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab
                label="Basics"
                value={1}
                disabled={!edit && statusAddUser === "succeeded"}
                enabled={edit && statusAddUser !== "succeeded"}
              />
              <Tab
                label="Levels"
                value={2}
                disabled={
                  (!edit && statusAddUser !== "succeeded") ||
                  (edit && user?.role === "admin") ||
                  edit & adminRoleChecked
                }
                enabled={statusAddUser === "succeeded" && edit}
              />
              {/* <Tab label="Points" value={3} disabled={!edit} />
              <Tab label="Subscriptions" value={4} disabled={!edit} /> */}
            </TabList>
          </div>

          <TabPanel value={1}>
            <Formik
              initialValues={{
                name: edit ? user.name : "",
                lastName: edit ? user.lastName : "",
                email: edit ? user.email : "",
                phoneNumber: edit ? user.phoneNumber : "",
                password: "",
                passwordConfirm: "",
                gender: edit ? user.gender : "",
                role: edit ? user.role : "",
              }}
              validationSchema={Yup.object().shape({
                name: edit
                  ? Yup.string().max(15)
                  : Yup.string()
                      .max(15)
                      .required(),
                lastName: edit
                  ? Yup.string().max(15)
                  : Yup.string()
                      .max(15)
                      .required(),
                email: edit
                  ? Yup.string().email()
                  : Yup.string()
                      .email()
                      .required(),
                phoneNumber: edit ? Yup.string().matches(/^\d+$/,"Phone Number Must Contain Only Numbers").length(8):
                   Yup.string().matches(/^\d+$/,"Phone Number Must Contain Only Numbers").length(8).required(),

                password: edit
                  ? Yup.string()
                      .min(8)
                      .matches(
                        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                        "Password must contain at least 8 characters, one uppercase, one number and one special case character"
                      )
                  : Yup.string()
                      .required("Please enter your password")
                      .min(8)
                      .matches(
                        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                        "Password must contain at least 8 characters, one uppercase, one number and one special case character"
                      ),
                passwordConfirm: edit
                  ? Yup.string().oneOf([Yup.ref("password")])
                  : Yup.string()
                      .oneOf([Yup.ref("password"), null])
                      .required(),
                gender: edit ? Yup.string() : Yup.string().required(),
                role: edit ? Yup.string() : Yup.string().required(),
              })}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
              ) => {
                try {
                  if (isMountedRef.current) {
                    setStatus({ success: true });
                    setSubmitting(false);
                    if (edit) {
                      values.id = user._id;
                      
                      if (values.password && values.passwordConfirm ) {
                        dispatch(updateUserPassword({id:user?._id,password:values.password,passwordConfirm:values.passwordConfirm}));
                        delete values.password
                        delete values.passwordConfirm
                      }

                      if (values.email !== user?.email){
                        dispatch(sendEmailUpdateToken({id:values.id,email:user.email,newEmail:values.email}))
                        delete values.email
                      }
                      
                      delete values.email
                      delete values.password
                      delete values.passwordConfirm
                      dispatch(editUser(values))

                    }
                    if (!edit) {
                      dispatch(addNewUser(values));
                      setValues(values);
                    }
                  }
                } 
                catch (err) {
                  console.log("FORM FILE , CATCH ERR", err);
                  if (isMountedRef.current) {
                    setStatus({ success: false });
                    setErrors({ submit: err.message });
                    setSubmitting(false);
                  }
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form
                  autoComplete="off"
                  noValidate
                  onSubmit={handleSubmit}
                  {...rest}
                >
                  <CardHeader subheader="The information can be edited" />
                  <Divider />
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid
                        item
                        md={3}
                        xs={12}
                        inputProps={{
                          form: {
                            autocomplete: "off",
                          },
                        }}
                      >
                        <TextField
                          error={Boolean(touched.name && errors.name)}
                          helperText={touched.name && errors.name}
                          fullWidth
                          autoComplete="none"
                          label="First name"
                          name="name"
                          required
                          onBlur={handleBlur}
                          onChange={handleChange}
                          defaultValue={edit ? user.name : ""}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid
                       item md={3} xs={12}>
                        <TextField
                          error={Boolean(touched.lastName && errors.lastName)}
                          helperText={touched.lastName && errors.lastName}
                          fullWidth
                          autoComplete="none"
                          label="Last name"
                          name="lastName"
                          required
                          onBlur={handleBlur}
                          onChange={handleChange}
                          defaultValue={edit ? user.lastName : ""}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid
                       item md={3} xs={12}>
                        <TextField
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                          fullWidth
                          inputProps={{
                            form: {
                              autocomplete: "off",
                            },
                          }}
                          label="Email Address"
                          name="email"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          required
                          defaultValue={edit ? user.email : ""}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid 
                      item md={3} xs={12}>
                        <TextField
                          error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                          helperText={touched.phoneNumber && errors.phoneNumber}
                          fullWidth
                          inputProps={{
                            form: {
                              autocomplete: "off",
                            },
                          }}
                          autoComplete="none"
                          label="Phone Number"
                          name="phoneNumber"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          defaultValue={edit ? user.phoneNumber : ""}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid 
                      item md={3} xs={12}>
                        <TextField
                          error={Boolean(touched.password && errors.password)}
                          helperText={touched.password && errors.password}
                          fullWidth
                          inputProps={{
                            form: {
                              autocomplete: "off",
                            },
                          }}
                          label="password"
                          name="password"
                          autoComplete="none"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          type="password"
                        />
                      </Grid>

                      <Grid
                       item md={3} xs={12}>
                        <TextField
                          error={Boolean(
                            touched.passwordConfirm && errors.passwordConfirm
                          )}
                          helperText={
                            touched.passwordConfirm && errors.passwordConfirm
                          }
                          fullWidth
                          inputProps={{
                            form: {
                              autocomplete: "off",
                            },
                          }}
                          label="passwordConfirm"
                          name="passwordConfirm"
                          autoComplete="none"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          type="password"
                        />
                      </Grid>

                      <Grid 
                      item md={3} xs={12}>
                        {
                          <FormControl fullWidth variant="outlined">
                            <InputLabel id="role-select-label">
                              Role
                            </InputLabel>
                            <Select
                              labelId="role-select-label"
                              id="role-select"
                              label="role"
                              name="role"
                              defaultValue={edit && user?.role ?user.role:""}
                              error={Boolean(touched.role && errors.role)}
                              helperText={
                                touched.role && errors.role
                                  ? errors.role
                                  : ""
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                            >
                              {roles && roles ? (
                                roles.map((role) => (
                                  <MenuItem value={role.name}>
                                    {role.name}
                                  </MenuItem>
                                ))) 
                                : 
                                ( <></> )}
                            </Select>
                          </FormControl>
                        }
                      </Grid>

                      <Grid
                       item md={3} xs={12}>
                        {
                          <FormControl fullWidth variant="outlined">
                            <InputLabel id="gender-select-label">
                              Gender
                            </InputLabel>
                            <Select
                              labelId="gender-select-label"
                              id="gender-select"
                              label="gender"
                              name="gender"
                              defaultValue={edit && user?.gender ?user.gender:""}
                              error={Boolean(touched.gender && errors.gender)}
                              helperText={
                                touched.gender && errors.gender
                                  ? errors.gender
                                  : ""
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                            >
                              {genders && genders ? (
                                genders.map((gender) => (
                                  <MenuItem value={gender.name}>
                                    {gender.name}
                                  </MenuItem>
                                ))
                              ) : (
                                <></>
                              )}
                            </Select>
                          </FormControl>
                        }
                      </Grid>

                    </Grid>
                  </CardContent>
                  <Divider />
                  {errors.submit && (
                    <Box mt={3}>
                      <FormHelperText error>{errors.submit}</FormHelperText>
                    </Box>
                  )}
                  <CardActions>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      variant="contained"
                      type="submit"
                    >
                      Save details
                    </Button>
                  </CardActions>
                </form>
              )}
            </Formik>
          </TabPanel>

          <TabPanel value={2}>
            <Formik
              initialValues={{
                section: edit ? user?.section?.name : "",
                level: edit ? user.level?.name : "",
              }}
              validationSchema={Yup.object().shape({
                section: edit ? Yup.string() : Yup.string(),
                level: edit ? Yup.string() : Yup.string(),
              })}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
              ) => {
                try {

                  if (isMountedRef.current) {
                    setStatus({ success: true });
                    setSubmitting(false);

                    if (edit) {
                      values.id = user._id;
                      if (values?.section !== user?.section?.name &&values?.level === user?.level?.name ) {
                        values.level = "";
                      }
                      if (values?.section?.length < 1) {
                        handleSnackBar("error", "section is required");
                      }
                      if (values?.level?.length < 1) {
                        handleSnackBar("error", "level is required");
                      }
                      if (values?.section?.length > 0 &&  values?.level?.length > 0  ) {
                          let thisSection
                          sections.filter((section)=>section.name===values?.section?thisSection=section:"")
                          let sectionLvls=[]
                          thisSection.levels.filter((lvl)=>sectionLvls.push(lvl.name))
                          if (!sectionLvls.includes(values.level)){
                            values.level=""
                          }
                      }
                      if (values.section.length > 0 && values.level.length > 0  ) {
                        sections.filter((section)=>section.name===values.section?values.section=section._id:"")
                        levels.filter((level)=>level.name===values.level?values.level=level._id:"")
                        dispatch(editUser(values));
                      }
                    }
                    if (!edit) {
                      values.id = newUserId;
                      let lvlsNames;

                      if (getLevelsSection) {
                        lvlsNames = getLevelsSection?.map((lvl) => lvl.name);
                      }
                      if (values.section.length < 1) {
                        return handleSnackBar("error", "level is required");
                      }
                      if (values.level.length < 1) {
                        return handleSnackBar("error", "level is required");
                      }
                      if ( values.section.length > 0 &&values.level.length > 0 &&!lvlsNames.includes(values.level) ) {
                        return handleSnackBar("error", "level is required");
                      }
                      if ( values.section.length > 0 &&    values.level.length > 0 && lvlsNames.includes(values.level)  ) {
                        sections.filter((section)=>section.name===values.section?values.section=section._id:"")
                        levels.filter((level)=>level.name===values.level?values.level=level._id:"")
                        values.id=testId._id
                        dispatch(editUser(values));
                      }
                    }
                  }
                } catch (err) {
                  console.log("FORM FILE , CATCH ERR", err);
                  if (isMountedRef.current) {
                    setStatus({ success: false });
                    setErrors({ submit: err.message });
                    setSubmitting(false);
                  }
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form
                  autoComplete="off"
                  noValidate
                  onSubmit={handleSubmit}
                  {...rest}
                >
                  <Divider />
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item md={3} xs={12}>
                        {
                          <FormControl fullWidth variant="outlined">
                            <InputLabel id="demo-simple-select-label">
                              Section
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="section"
                              name="section"
                              defaultValue={edit && user?.section?.name ?user.section.name:""}
                              error={Boolean(touched.section && errors.section)}
                              helperText={
                                touched.section && errors.section
                                  ? errors.section
                                  : ""
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                            >
                              {sectionsName && sectionsName ? (
                                sectionsName.map((sectName) => (
                                  <MenuItem value={sectName}>
                                    {sectName}
                                  </MenuItem>
                                ))
                              ) : (
                                <></>
                              )}
                            </Select>
                          </FormControl>
                        }
                      </Grid>

                      {values.section &&
                      sections.filter((sect) =>
                        sect.name === values.section
                          ? setGetLevelsSection(sect.levels)
                          : ""
                      ) ? (
                        <Grid item md={3} xs={12}>
                          <FormControl
                            error={Boolean(touched.level && errors.level)}
                            helperText={touched.level && errors.level}
                            fullWidth
                            variant="outlined"
                          >
                            <InputLabel id="demo-simple-select-label">
                              Level
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              defaultValue={edit && user?.level?.name ?user.level.name:""}
                              label="level"
                              name="level"
                              onBlur={handleBlur}
                              onChange={handleChange}
                            >
                              {sections && getLevelsSection ? (
                                getLevelsSection.map((level) => (
                                  <MenuItem value={level.name}>
                                    {level.name}
                                  </MenuItem>
                                ))
                              ) : (
                                <></>
                              )}
                            </Select>
                          </FormControl>
                        </Grid>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </CardContent>
                  <Divider />
                  {errors.submit && (
                    <Box mt={3}>
                      <FormHelperText error>{errors.submit}</FormHelperText>
                    </Box>
                  )}
                  <CardActions>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      variant="contained"
                      type="submit"
                    >
                      Save details
                    </Button>
                  </CardActions>
                </form>
              )}
            </Formik>
          </TabPanel>

          <TabPanel value={3}>
            <PointsForm points={points} userId={id} />
          </TabPanel>

          <TabPanel value={4}>
            <SubscriptionsForm userId={id} />
          </TabPanel>
        </TabContext>
      </Card>

      <SnackBar
        open={open}
        message={message}
        severity={severity}
        handleClose={handleClose}
      />
    </div>
  );
};

Form.propTypes = {
  className: PropTypes.string,
  handleCloseLoading: PropTypes.func,
};

export default Form;
