// @flow
import React from "react";
import { Doughnut } from "react-chartjs-2";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Divider,
  Typography,
} from "@material-ui/core";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ModeIcon from "@mui/icons-material/Mode";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import RefreshIcon from "@material-ui/icons/Refresh";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  chartContainer: {
    position: "relative",
    height: "300px",
  },
  stats: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
  device: {
    textAlign: "center",
    padding: theme.spacing(1),
  },
  deviceIcon: {
    color: theme.palette.icon,
  },
}));
type Props = {
  className: string,
};
const UsersByDevice = (props: Props): React$Element<any> => {
  const { className, apps, ...rest } = props;

  const classes = useStyles();
  const theme = useTheme();
  let keys = [];
  let values = [];
  let keysAndValues = [];
  if (apps) {
    for (const [key, value] of Object.entries(apps)) {
      keys.push(key);
      values.push(value);
      keysAndValues.push([key, value.percentage]);
    }
  }
  const data = {
    datasets: [
      {
        data: values?.map((e) => e.percentage),
        backgroundColor: [
          theme.palette.primary.main,
          theme.palette.error.main,
          theme.palette.warning.main,
          theme.palette.grey[400],
          theme.palette.secondary.main,
        ],
        borderWidth: 8,
        borderColor: theme.palette.white,
        hoverBorderColor: theme.palette.white,
      },
    ],
    labels: keys,
  };
  const options = {
    legend: {
      display: false,
    },
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    tooltips: {
      enabled: true,
      mode: "index",
      intersect: false,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary,
    },
  };
  const icons = [
    <ModeIcon />,
    <DoneIcon />,
    <DoneAllIcon />,
    <CheckCircleIcon />,
    <CloseIcon />,
  ];
  
  const devices = keysAndValues?.map((el, index) => ({
    title: el[0],
    value: el[1],
    icon: icons[index],
    color: data?.datasets[0].backgroundColor[index],
  }));

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        action={
          <IconButton size="small">
            <RefreshIcon />
          </IconButton>
        }
        title="Applications By Status"
      />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          <Doughnut data={data} options={options} />
        </div>
        <div className={classes.stats} style={{ flexWrap: "wrap" }}>
          {devices.map((device) => (
            <div className={classes.device} key={device.title}>
              <span className={classes.deviceIcon}>{device.icon}</span>
              <Typography variant="body1">{device.title}</Typography>
              <Typography style={{ color: device.color }} variant="h3">
                {device.value}%
              </Typography>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

UsersByDevice.propTypes = {
  className: PropTypes.string,
};

export default UsersByDevice;
