import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardActions,
  CardContent,
  TablePagination,
} from "@material-ui/core";

import Action from "../Action/Action";
import TablePaginationActions from "./TablePaginationActions";
import CustomTable from "./CustomTable";

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
    [theme.breakpoints.down("xs")]: {
      minWidth: 50,
    },
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
  hidden: {
    display: "none",
  },
}));

const Table = (props) => {
  const {
    className,
    data,
    columnsChange,
    meta,
    columns,
    handleSortChange,
    sort,
    handlePageChange,
    ActionDeleteMany,
    rowsPerPage,
    handleRowsPerPageChange,
    page,
    tableService,
    deleteFunction,
    pageLink,
    initializeData,
    deleteUrl,
    ...rest
  } = props;

  const classes = useStyles();
  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <CustomTable
              columns={columns}
              data={data}
              sort={sort}
              columnsChange={columnsChange}
              handleSortChange={handleSortChange}
              ActionComponent={Action}
              page={page}
              tableService={tableService}
              rowsPerPage={rowsPerPage}
              pageLink={pageLink}
              initializeData={initializeData}
              deleteUrl={deleteUrl}
            />
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={meta?.totalDocs}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          ActionsComponent={TablePaginationActions}
        />
      </CardActions>
    </Card>
  );
};

Table.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.array,
  data: PropTypes.object,
  handleSortChange: PropTypes.func,
  sort: PropTypes.object,
  handlePageChange: PropTypes.func,
  page: PropTypes.number,
  deleteUrl: PropTypes.string,
};

export default Table;
