import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api_delete, api_get,  api_post,  api_put } from "utils/Api";


const initialState = {
  sections: [],
  meta:[],
  status: "idle",
  error: null,
  newSection:[],
  statusAddNewSection:"init",
  errorAddNewSection:null,
  updatedSection:"",
  statusUpdatedSection:"init",
  errorUpdateSection:null,
  deletedSection:[],
  statusDeletedSection:"init",
  errorDeletedSection:null,
  section:"",
  errorSection:null,
  statusSelectedSection:"init",
  filteredValue:[],
};

export const fetchAllSections = createAsyncThunk("sections/fetchAllSections", async (queryParams) => {
  let data;
     if (queryParams){
        if(Object.keys(queryParams).includes("createdAt") ){
          const values=queryParams["createdAt"]
          delete queryParams.createdAt
          queryParams['createdAt[gte]']=values[0]+"T00:00:00.000Z"
          queryParams['createdAt[lte]']=values[1]+"T23:59:59.999Z"
        }
     }

    try {
      const response = await api_get(`api/v1/sections/`, queryParams);
      data = await response;
      if (response.status = 200) {
      return data;
      }
      throw new Error(response.message);
    } 
    catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
});

export const fetchOneSection= createAsyncThunk("sections/fetchOneSection", async (sectionId) => {
  let id=sectionId
  let data;
    try {
      const response = await api_get(`api/v1/sections/${id}`, );
      data = await response.data;
      if (response.status = 200) {
  
      return data;
      }
      throw new Error(response.statusText);
    } 
    catch (err) {
      console.log("err in fetchOneSection",err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
});

export const editSection=createAsyncThunk("sections/editSection",async(body,thunkAPI)=>{ 
const Data=body
const id=Data?.id
delete Data.id

   let data;
   try {
     const response = await api_put(`api/v1/sections/${id}/`,Data);
     data = await response.data;
     console.log(response)
      if (response.status === "success") {
      thunkAPI.dispatch(fetchOneSection(id))
      thunkAPI.dispatch(fetchAllSections());
       return data;
      }
    throw new Error(response.message);
  }
   catch (err) {
    console.log("ERROR EDIT SECTION ",err)
    return Promise.reject(err.message ? err.message : data?.message);
  }

})

export const addNewSection=createAsyncThunk("sections/addNewSection",async(body,thunkAPI)=>{
  let Data=body
  let data; 
  console.log("DATA FROM SLICE ",Data)

  try{ 
    const response=await api_post(`api/v1/sections/`,Data)  
    data=await response.data;
    if(response.status==="success"){
      thunkAPI.dispatch(fetchAllSections())
      return data
    }
    throw new Error(response.message);
  }
  catch(err){
    console.log("ERROR REQUEST ADD NEW SECTION ",err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
})

export const deleteOneSection=createAsyncThunk("sections/deleteOneSection",async(body,thunkAPI)=>{ 
  const id=body.id
  delete body.id 
  let queries=body 
  let data;
     try {
       const response = await api_delete(`api/v1/sections/${id}`);
       data = await response.data;
        if (response.status ==="success") {
         thunkAPI.dispatch(fetchAllSections(queries));
         return data;
        }
      throw new Error(response.message);
    }
     catch (err) {
      console.log(err)
      return Promise.reject(err.message ? err.message : data?.message);
    }
})

const slice = createSlice({
  name: "sections",
  initialState,
  reducers: {
    changeStatusAddNewSection:(state,action)=>{
      state.statusAddNewSection="init"
      state.errorAddNewSection=null
      state.newSection=""
    },
    changeStatusEditSection:(state,action)=>{
      state.statusUpdatedSection="init"
      state.errorUpdateSection=null
      state.updatedSection=""
    },
    changeStatusDeletedSection:(state,action)=>{
      state.statusDeletedSection="init"
      state.errorDeletedSection=null
      state.deletedSection=""
    },
    removeSelectedSection:(state,action)=>{
      state.statusSelectedSection="init"
      state.errorSection=null
      state.section=""
    },
    saveFilteredValuesSections:(state,action)=>{
      state.filteredValue=action.payload
    },
  },

  extraReducers: {
    [fetchAllSections.pending]: (state) => {
      state.status = "loading";
    },
    [fetchAllSections.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.sections = action.payload.data;
      state.meta=action.payload.meta
    },
    [fetchAllSections.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [fetchOneSection.pending]: (state) => {
      state.statusSelectedSection = "loading";
    },
    [fetchOneSection.fulfilled]: (state, action) => {
      state.statusSelectedSection = "succeeded";
      state.section = action.payload;
    },
    [fetchOneSection.rejected]: (state, action) => {
      state.statusSelectedSection = "failed";
      state.errorSection= action.payload;
    },
    [addNewSection.pending]: (state) => {
      state.statusAddNewSection = "loading";
    },
    [addNewSection.fulfilled]: (state, action) => {
      state.statusAddNewSection = "succeeded";
      state.newSection= action.payload;
    },
    [addNewSection.rejected]: (state, action) => {
      state.statusAddNewSection = "failed";
      state.errorAddNewSection = action.error.message;
    },
    [editSection.pending]: (state) => {
      state.statusUpdatedSection = "loading";
    },
    [editSection.fulfilled]: (state, action) => {
      state.statusUpdatedSection = "succeeded";
      state.updatedSection = action.payload;
    },
    [editSection.rejected]: (state, action) => {
      state.statusUpdatedSection = "failed";
      state.errorUpdateSection = action.error.message;
    },
    [deleteOneSection.pending]: (state) => {
      state.statusDeletedSection = "loading";
    },
    [deleteOneSection.fulfilled]: (state, action) => {
      state.statusDeletedSection = "succeeded";
      state.deletedSection = action.payload;
    },
    [deleteOneSection.rejected]: (state, action) => {
      state.statusDeletedSection = "failed";
      state.errorDeletedSection = action.payload;
    },
  },
});

export const reducer = slice.reducer;
export const {changeStatusEditSection,changeStatusAddNewSection,saveFilteredValuesSections ,
   removeSelectedSection , changeStatusDeletedSection} = slice.actions;
export default slice;