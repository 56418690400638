import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Avatar, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { removeSelectedUserFromStore } from "redux/slices/usersSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "fit-content",
  },
  avatar: {
    width: 60,
    height: 60,
  },
  name: {
    marginTop: theme.spacing(1),
  },
}));

const Profile = (props) => {
  const { className,profil, ...rest } = props;
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [user, setUser] = useState([]);
  const userData=useSelector((state)=>state.users.user)
  const dispatch=useDispatch()

  // useEffect(()=>{
  //   if(props.user){
  //     setUser(props.user)
  //   }
  // },[props])
  
  // useEffect(()=>{
  //   if(Object.keys(userData).length>0){
  //     setUser(userData)
  //   }
  // },[userData])
  // useEffect(() => {
  //   if (data) {
  //     if (data.roles) {
  //       setUser({
  //         ...user,
  //         name: (data && data.name) || "",
  //         bio: (data?.roles[0]) || "",
  //       });
  //     }
  //   }
  // }, [data]);

  const PF = process.env.REACT_APP_PF_DOWNLOAD;
  const picturePath = (firstPath) => {
    const lastPartOfPath = firstPath?.split("/")[2];
    const str = PF + lastPartOfPath;
    return str;
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Avatar
        alt='Person'
        className={classes.avatar}
        component={RouterLink}
        to='/account'
      />
      {user ?<Typography className={classes.name} variant='h4'>
        {(user && user.name) || ""}
      </Typography>:<br/>}
      {/* {profil ?<Typography variant='body2'>{(user && `ROLE_${user.role}`.toUpperCase()) || ""}</Typography>:<br/> } */}
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string,
};

export default Profile;
