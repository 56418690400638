import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';

import {Form} from './components';
import SimpleToolbar from '../../components/Toolbar/SimpleToolbar';
import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchOneUser } from 'redux/slices/usersSlice';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));


const UserEdit = (props) => {
  const {Link , pageLink, title, ...rest} = props;
  const {id}=useParams()
  const classes = useStyles();
    
  const dispatch=useDispatch()  
  useEffect(()=>{
    dispatch(fetchOneUser(id))
  },[dispatch])

  const userData=useSelector((state)=>state.users.user)
  const statusUser = useSelector((state)=> state.users.statusSelectedUser)

  
  return (
    <div className={classes.root}>
      <SimpleToolbar
         Link = {Link}
         pageLink = {'/users/students'}
         title = {'Students'} />
      <div className={classes.content}>
        <Form  edit={true} user={userData} statusUser={statusUser}  />
      </div>
    </div>
  );
};

UserEdit.propTypes = {
  user: PropTypes.object,
};

export default UserEdit;
