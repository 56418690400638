import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api_delete, api_get, api_put } from "utils/Api";

const initialState = {
  applications: [],
  oneApplication: "",
  status: "idle",
  updateStatus:"idle",
  item: [],
  meta: [],
  error: null,
  filteredValueApplications: [],
  deleteStatus: "idle"
};

export const fetchApplications = createAsyncThunk(
  "applications/fetchApplications",
  async (queryparams) => {
    if (queryparams?.createdAt) {
      let dayPlusOne =
        queryparams.createdAt[1].split("-")[0] +
        "-" +
        queryparams.createdAt[1].split("-")[1] +
        "-" +
        (Number(queryparams.createdAt[1].split("-")[2]) + 1).toString();
      queryparams = {
        ...queryparams,
        [`createdAt[gte]`]: queryparams.createdAt[0],
        [`createdAt[lte]`]: dayPlusOne,
      };
      delete queryparams.createdAt;
    }
    let data;
    try {
      const response = await api_get("api/v1/applications", queryparams);
      data = await response;
      if (response.status === "Success") {
        return data;
      }
      throw new Error(response.message);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const fetchOneApplication = createAsyncThunk(
  "applications/fetchOneApplication",
  async (id) => {
    let data;
    try {
      const response = await api_get(`api/v1/applications/${id}`);
      data = await response.data;
      if (response.status === "Success") {
        return data;
      }
      throw new Error(response.message);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const updateApplication = createAsyncThunk(
  "applications/updateApplication",
  async (values, thunkAPI) => {
    let data;
    let id = values.id;
    delete values.id;
    try {
      const response = await api_put(
        `api/v1/applications/update/${id}`,
        values
      );
      data = await response.data;
      if (response.status ==="Success") {
        thunkAPI.dispatch(fetchOneApplication(id));
        return data;
      }
      throw new Error(response.message);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const deleteApplication = createAsyncThunk(
  "application/deleteApplication",
  async (query, thunkAPI) => {
    let id = query.id;
    delete query.id;
    let data;
    try {
      const response = await api_delete(`api/v1/applications/${id}`);
      data = await response.data;
      if (response.status === "Success") {
        thunkAPI.dispatch(fetchApplications(query));
        return data;
      }
      throw new Error(response.message);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: "applications",
  initialState,
  reducers: {
    getItem: (state, action) => {
      state.item = action.payload;
    },
    saveFilteredValuesApplications: (state, action) => {
      state.filteredValueApplications = action.payload;
    },
    changeDeleteApplicationStatus:(state,action)=> {
      state.deletestatus=action.payload;
    }
  },
  extraReducers: {
    [fetchApplications.pending]: (state) => {
      state.status = "loading";
    },
    [fetchApplications.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.applications = action.payload.data;
      state.meta = action.payload.meta;
    },
    [fetchApplications.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [fetchOneApplication.pending]: (state) => {
      state.status = "loading";
    },
    [fetchOneApplication.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.oneApplication = action.payload;
    },
    [fetchOneApplication.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [deleteApplication.pending]: (state) => {
      state.deleteStatus = "loading";
    },
    [deleteApplication.fulfilled]: (state, action) => {
      state.deleteStatus = "succeeded";
      state.applications = action.payload;
    },
    [deleteApplication.rejected]: (state, action) => {
      state.deleteStatus = "failed";
      state.error = action.payload;
    },
    [updateApplication.pending]: (state) => {
      state.updateStatus = "loading";
    },
    [updateApplication.fulfilled]: (state, action) => {
      state.updateStatus = "succeeded";
    },
    [updateApplication.rejected]: (state, action) => {
      state.updateStatus = "failed";
      state.error = action.payload;
    },
  },
});
export const reducer = slice.reducer;
export const { getItem, saveFilteredValuesApplications , changeDeleteApplicationStatus } = slice.actions;

export default slice;
