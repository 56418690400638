import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Divider,
  makeStyles,
  Tab,
  Tabs,
} from "@material-ui/core";
import Page from "../../components/Page";
import ChapterInfo from "./ApplicationShow/Index";
import { useParams } from "react-router-dom";
import SimpleToolbar from "../../components/Toolbar/SimpleToolbar";
import { useDispatch, useSelector } from "react-redux";
import { fetchOneApplication } from "redux/slices/applications";
import Loading from "components/Loading/Loading";
import Notify from "./ApplicationShow/Notify";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

const UserShow = (props) => {
  const { Link, pageLink, title, ...rest } = props;
  const { id } = useParams();
  const classes = useStyles();
  const [offer, setOffer] = useState([]);
  const [currentTab, setCurrentTab] = useState("details");
  const dispatch = useDispatch();
  const tabs = [
    { value: "details", label: "Application Details" },
    { value: "notify", label: "Notify user" },
  ];

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  useEffect(() => {
    dispatch(fetchOneApplication(id));
  }, [dispatch, id]);
  const { oneApplication, status } = useSelector((state) => state.applications);
  
  if (status === "loading" || status === "idle") {
    return <Loading />;
  }

  return (
    <div>
      <SimpleToolbar
        Link={Link}
        pageLink={"/contents/applications"}
        title={"Applications"}
      />
      <Page className={classes.root} title="Application Details">
        <Container maxWidth={false}>
          <Box mt={3}>
            <Tabs
              onChange={handleTabsChange}
              scrollButtons="auto"
              value={currentTab}
              variant="scrollable"
              textColor="secondary"
            >
              {tabs.map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </Tabs>
          </Box>
          <Divider />
          <Box mt={3}>
            {currentTab === "details" && (
              <ChapterInfo application={oneApplication} />
            )}
            {currentTab === "notify" && <Notify user={oneApplication.user} />}
          </Box>
        </Container>
      </Page>
    </div>
  );
};

export default UserShow;
