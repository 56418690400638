import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import Toolbar from "../../components/Toolbar/Toolbar";
import Table from "../../components/Table/Table";
import Collapse from "@material-ui/core/Collapse";
import Columns from "../../components/Columns";
import Filter from "../../components/Filter/Filter";
import { useDispatch, useSelector } from "react-redux";
import { fetchOffers, saveFilteredValuesOffers } from "redux/slices/offers";
import { fetchAllSections } from "redux/slices/sectionSlice";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

const columnsList = [
  { accessor: "_id", name: "id", label: "Id", width: "10%", show: true },
  { accessor: "Name", label: "Name", width: "18%", show: true },
  { accessor: "section.name", label: "Section", width: "7%", show: true },
  {
    accessor: "startsAt",
    label: "startsAt",
    type: "date",
    width: "15%",
    show: true,
  },
  {
    accessor: "ExpiresIn",
    label: "ExpiresIn",
    type: "date",
    width: "15%",
    show: true,
  },
  {
    accessor: "createdAt",
    type: "date",
    label: "createdAt",
    width: "15%",
    show: true,
  },
  { accessor: "location", label: "Location", width: "8%", show: true },
  {
    accessor: "vacancies",
    label: "Vacancies",
    type: "Number",
    width: "10%",
    show: true,
  },
  {
    accessor: "active",
    label: "active",
    type: "boolean",
    width: "10%",
    show: true,
  },
];

const fieldSearchable = ["id"];
const fieldFilterable = [
  { name: "createdAt", label: "Created At", type: "dateRange" },
  {
    name: "section",
    label: "Section",
    type: "dropdown",
    options: ["Bac", "Licence"],
  },
  { name: "ExpiresIn", label: "Expires In", type: "date" },
  { name: "startsAt", label: "Starts At", type: "date" },
  { name: "active", label: "active", type: "boolean" },
];
const ChapterList = () => {
  const [search, searchChange] = useState("");
  const [filters, filtersChange] = useState({});
  const [columns, columnsChange] = useState(columnsList);
  const [sort, sortChange] = useState({ accessor: "id", order: "desc" });
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [columnsOpen, setColumnsOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [data, setData] = useState([]);
  const classes = useStyles();
  const dispatch = useDispatch();   
  const { offers, meta, filteredValueOffers } = useSelector(
    (state) => state?.offers
  ); 

  useEffect(() => {
    dispatch(fetchAllSections());
  }, [dispatch]);
  const { sections } = useSelector((state) => state?.sections);
  
  if (sections) {
    fieldFilterable.filter((field) =>
      field.name === "section" ? field.options=sections : ""
    );
  }

  useEffect(() => {
    if (filteredValueOffers === null) {
      filtersChange({});
      dispatch(saveFilteredValuesOffers([]));
    }
  }, [filteredValueOffers, dispatch, filters]);

  useEffect(() => {
    if (search.length < 1 && Object.keys(filters).length < 1) {
      dispatch(fetchOffers({ page: page + 1, limit: rowsPerPage }));
    }
  }, [dispatch, filters, page, search, rowsPerPage]);

  useEffect(() => {
    if (search.length > 0 && Object.keys(filters).length < 1) {
      const timeOut = setTimeout(() => {
        dispatch(
          fetchOffers({ page: page + 1, limit: rowsPerPage, search: search })
        );
      }, 300);
      return () => {
        clearTimeout(timeOut);
      };
    }
  }, [dispatch, page, filters, search, rowsPerPage]);

  const toggleFilters = () => {
    setFiltersOpen(!filtersOpen);
  };

  const toggleColumns = () => {
    setColumnsOpen(!columnsOpen);
  };

  const handleSortChange = (accessor) => {
    sortChange({
      accessor: accessor,
      order: sort.order === "asc" ? "desc" : "asc",
    });
  };

  useEffect(() => {
    setData(offers);
  }, [offers]);

  useEffect(() => {
    if (search.length > 0) {
      setPage(0);
    }
  }, [search]);

  useEffect(() => {
    if (Object.keys(filters).length > 0) {
      setPage(0);
    }
  }, [filters]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const initializeData = (num, rows) => {
    setPage(num);
    setRowsPerPage(rows);
  };
  return (
    <div className={classes.root}>
      <Toolbar
        toggleFilters={toggleFilters}
        toggleColumns={toggleColumns}
        searchChange={searchChange}
        pageLink={"/contents/offers"}
        searchMessage={"Search by Name"}
      />
      <div className={classes.content}>
        <Collapse in={columnsOpen}>
          <Columns columnsChange={columnsChange} columns={columns} />
        </Collapse>
        <Collapse in={filtersOpen}>
          <Filter
            FilterType={"offers"}
            fields={fieldFilterable}
            values={filters}
            filtersChange={filtersChange}
            page={page + 1}
            search={search}
            rowsPerPage={rowsPerPage}
            initializeData={initializeData}
            sections={sections}
          />
        </Collapse>
        <Table
          columns={columns}
          data={data}
          meta={meta}
          handleSortChange={handleSortChange}
          sort={sort}
          handlePageChange={handleChangePage}
          page={page}
          tableService={"offers"}
          rowsPerPage={rowsPerPage}
          handleRowsPerPageChange={handleChangeRowsPerPage}
          pageLink={"/contents/offers"}
        />
      </div>
    </div>
  );
};

export default ChapterList;
