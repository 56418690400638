import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api_delete, api_get, api_post, api_put } from "utils/Api";
import { openSnackBar } from "./snackbar";

const initialState = {
  offers: [],
  oneOffer: "",
  status: "idle",
  updateStatus: "idle",
  oneOfferStatus: "idle",
  item: [],
  meta: [],
  error: null,
  updateError: null,
  deletestatus: null,
  filteredValueOffers: [],
};

export const fetchOffers = createAsyncThunk(
  "offers/fetchOffers",
  async (queryparams) => {
    if (queryparams?.createdAt) {
      let dayPlusOne =
        queryparams.createdAt[1].split("-")[0] +
        "-" +
        queryparams.createdAt[1].split("-")[1] +
        "-" +
        (Number(queryparams.createdAt[1].split("-")[2]) + 1).toString();
      queryparams = {
        ...queryparams,
        [`createdAt[gte]`]: queryparams.createdAt[0],
        [`createdAt[lte]`]: dayPlusOne,
      };
      delete queryparams.createdAt;
    }
    if (queryparams?.ExpiresIn) {
      let startDay = queryparams.ExpiresIn + "T00:00:00.000Z";
      let endDay = queryparams.ExpiresIn + "T23:59:59.999Z";
      queryparams = {
        ...queryparams,
        [`ExpiresIn[gte]`]: startDay,
        [`ExpiresIn[lte]`]: endDay,
      };
      delete queryparams.ExpiresIn;
    }
    if (queryparams?.startsAt) {
      let startDay = queryparams.startsAt + "T00:00:00.000Z";
      let endDay = queryparams.startsAt + "T23:59:59.999Z";
      queryparams = {
        ...queryparams,
        [`startsAt[gte]`]: startDay,
        [`startsAt[lte]`]: endDay,
      };
      delete queryparams.startsAt;
    }

    let data;
    try {
      const response = await api_get(`api/v1/offers`, queryparams);
      data = await response;
      if ((response.status = 200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const fetchOneOffer = createAsyncThunk(
  "offers/fetchOneOffer",
  async (id) => {
    let data;
    try {
      const response = await api_get(`api/v1/offers/${id}`);
      data = await response.data;
      if ((response.status = 200)) {
        return data;
      }
      throw new Error(response.message);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const deleteOffer = createAsyncThunk(
  "offer/deleteOffer",
  async (query, thunkAPI) => {
    let id = query.id
    delete query.id
    let data;
    try {
      const response = await api_delete(`api/v1/offers/${id}`);
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(fetchOffers(query));
        return data;
      }
      throw new Error(response.message);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const updateOffer = createAsyncThunk(
  "offers/updateOffer",
  async (values, thunkAPI) => {
    let data;
    let id = values.id;
    delete values.id;
    try {
      const response = await api_put(`api/v1/offers/${id}/update`, values);
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(fetchOneOffer(id));
        thunkAPI.dispatch(fetchOffers());
        return data;
      }

      throw new Error(response.message)

    } catch (err) {
      throw(err)

      // return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const createOffer = createAsyncThunk(
  "example/createOffer",
  async (values, thunkAPI) => {
    let data;
    try {
      const response = await api_post(`api/v1/offers/create`, values);
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(fetchOffers());
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      thunkAPI.dispatch(openSnackBar({ status: "error", msg: err.message }));
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: "offers",
  initialState,
  reducers: {
    saveFilteredValuesOffers: (state, action) => {
      state.filteredValueOffers = action.payload;
    },
  },
  extraReducers: {
    [fetchOffers.pending]: (state) => {
      state.status = "loading";
    },
    [fetchOffers.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.offers = action.payload.data;
      state.meta = action.payload.meta;
    },
    [fetchOffers.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [fetchOneOffer.pending]: (state) => {
      state.oneOfferStatus = "loading";
    },
    [fetchOneOffer.fulfilled]: (state, action) => {
      state.oneOfferStatus = "succeeded";
      state.oneOffer = action.payload;
    },
    [fetchOneOffer.rejected]: (state, action) => {
      state.oneOfferStatus = "failed";
      state.error = action.payload;
    },
    [deleteOffer.pending]: (state) => {
      state.deletestatus = "loading";
    },
    [deleteOffer.fulfilled]: (state, action) => {
      state.deletestatus = "succeeded";
      state.offers = action.payload;
    },
    [deleteOffer.rejected]: (state, action) => {
      state.deletestatus = "failed";
      state.error = action.payload;
    },
    [updateOffer.pending]: (state) => {
      state.updateStatus = "loading";
    },
    [updateOffer.fulfilled]: (state, action) => {
      state.updateStatus = "succeeded";
    },
    [updateOffer.rejected]: (state, action) => {
      state.updateStatus = "failed";
      state.updateError = action.error.message;
    },
    [createOffer.pending]: (state) => {
      state.status = "loading";
    },
    [createOffer.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.offers.push(action.payload);
    },
    [createOffer.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
  },
});
export const reducer = slice.reducer;
export const {saveFilteredValuesOffers} = slice.actions
export default slice;
