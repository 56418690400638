// @flow
import React, { useState, useRef, useEffect} from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Divider,
  Button,
  LinearProgress,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { changeStatusProfilePicture, editProfilePicture, editUser, removeSelectedUserFromStore } from 'redux/slices/usersSlice';
import { serialize } from 'object-to-formdata';
import SnackBar from 'components/SnackBar';

const useStyles = makeStyles((theme) => ({
  root: {},
  details: {
    display: 'flex',
  },
  avatar: {
    height: 110,
    width: 110,
    flexShrink: 0,
    flexGrow: 0,
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  uploadButton: {
    marginRight: theme.spacing(22),
  },
}));

type Props = {
  className: string
}

const AccountProfile = (props: Props): React$Element<any> => {
  const {className,user, ...rest} = props;

  const classes = useStyles();
  const dispatch=useDispatch()
  const [data, setData] = useState([]);
  // const [user, setUser] = useState([]);
  const [image, setImage] = useState([null, null]);
  const [imagePath,setImagePath]=useState("")
  const [values,setValues]=useState("")
  const [removed,setRemoved]=useState("")
  const [message, setAlertMessage] = useState("");
  const [severity, setAlertSeverity] = useState("");
  const [open, setOpen] = useState(false);
  const {statusEditProfilePicture}=useSelector((state)=>state?.users)

  const PF = process.env.REACT_APP_PF_DOWNLOAD;
  const picturePath = (firstPath) => {
    const lastPartOfPath = firstPath?.split("/")[2];
    const str = PF + lastPartOfPath;
    return str;
  };

  // useEffect(()=>{
  //   if (user){
  //     setValues(user)
  //   }
  // },[props])

  // useEffect(()=>{
  //   if (imagePath&&user){
  //     let data={}
  //     data.profilePicture=imagePath
  //     data.id=user._id
  //     dispatch(editProfilePicture(data))
  //   }
  // },[imagePath,user])

  // useEffect(()=>{
  //   if (removed){
  //     setImage([null,null])
  //     setImagePath("")
  //     let data={}
  //     data.id=user._id
  //     data.profilePicture=""
  //     dispatch(editUser(data))
  //     setRemoved(false)
  //   }
  // },[removed])
  
  // useEffect(()=>{
  //   if(statusEditProfilePicture==="succeeded"){
  //     handleSnackBar("success","ProfilePicture Changed Successfully")
  //     dispatch(changeStatusProfilePicture())
  //   }
  // },[statusEditProfilePicture])
  
  const uploadImage = (e, index) => {
    setImage((prevImages) => {
      prevImages[index] = URL.createObjectURL(e?.target?.files[0]);
      setImagePath(e?.target?.files[0])
      return [...prevImages];
    });
  };

  const handleSnackBar = (type, msg) => {
    setOpen(true);
    setAlertSeverity(type);
    setAlertMessage(msg);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <div className={classes.details}>
          <Avatar
              alt='Person'
              className={classes.avatar}
              src={statusEditProfilePicture==="succeeded" ?image[0]: picturePath(user?.profilePicture)}
            />
        </div>
      </CardContent>
      <Divider />
      <CardActions>
          <div className="upload-area-wrapper">
             <div className="upload-input">
                <input
                   type="file"
                   name="profilePicture"
                   accept=".png, .jpg, .jpeg"
                   onChange={(e) => uploadImage(e, 0)}
                   id="profilePicture"
                   style={{color:"transparent","width":"25%"}}
                />
               <label htmlFor="profilePicture">
                  <Button
                   variant="text"
                   color="primary">
                      Upload Picture
                  </Button>
              </label>
              </div>
           </div>
        <Button 
          variant="text" 
          onClick={()=>setRemoved(!removed)}
        >
          Remove picture
        </Button>
      </CardActions>
    </Card>
    <SnackBar
      open={open}
      message={message}
      severity={severity}
      handleClose={handleClose}
    />
    </div>
  );
};

export default AccountProfile;
