import React, { Fragment, lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import MinimalLayout from './layouts/Minimal';
import MainLayout from './layouts/Main';
import LoadingScreen from './components/LoadingScreen';
import AuthGuard from './components/AuthGuard';
import GuestGuard from './components/GuestGuard';
import { useStore } from './contexts/JWTAuthContext';
import { checkPermission } from './utils/acl/aclRouter';
import { Dashboard } from './views';
import LoginView from './views/LoginView';
import { Helmet } from 'react-helmet';
import jwtDecode from 'jwt-decode';
import { isValidToken } from './contexts/JWTAuthContext';

export const RenderRoutes = ({ routes }) => {
  const { user, isAuthenticated } = useStore();
  const token=window.localStorage.getItem("token")
  if (token && !isValidToken(token)){
    return window.location="/login"
  }
  
  return (

    <Suspense fallback={<LoadingScreen />}>

      <Switch>
        {
          routes.map((route, i) => {
            const Guard = route.guard || Fragment;
            const Layout = route.layout || Fragment;
            const Component = route.component;
            return (
              <Route
                key={i}
                path={route.path}
                exact={route.exact}
                render={(props) => (
                  <Guard>
                    <Layout>
                      <Helmet>
                        <title>{route.title}</title>
                      </Helmet>
                      {route.routes ? <RenderRoutes routes={route.routes} /> : <Component {...props} />}
                      {/* {route.routes ? (<RenderRoutes routes={route.routes}/> ) : 
                      ( user && checkPermission(route.path, user.roles,  user.admin_groups) ?
                         <Component {...props} />
                         :
                        !user && !isAuthenticated ?
                            <LoginView/>
                            :
                            <Dashboard/>
                              )}*/}
                    </Layout>
                  </Guard>
                )}
              />
            );
          })}
      </Switch>
    </Suspense>
  );
};

const routes = [
  {
    exact: true,
    layout: MinimalLayout,
    path: '/404',
    component: lazy(() => import('./views/NotFound')),
  },
  {
    exact: true,
    layout: MinimalLayout,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('./views/LoginView')),
  },
  {
    path: '/',
    guard: AuthGuard,
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: lazy(() => import('./views/Dashboard')),
      },
      {
        exact: true,
        path: '/account',
        component: lazy(() => import('./views/Account')),
        title: 'TA|Account'
      },

      {
        exact: true,
        path: '/users/students',
        component: lazy(() => import('./views/User/UserList')),
        title: 'TA|Students'
      },
      {
        exact: true,
        path: '/users/students/create',
        component: lazy(() => import('./views/User/UserCreate')),
        title: 'TA|Students-create'

      },
      {
        exact: true,
        path: '/users/students/edit/:id',
        component: lazy(() => import('./views/User/UserEdit')),
        title: 'TA|Students-edit'

      },
      {
        exact: true,
        path: '/users/students/view/:id',
        component: lazy(() => import('./views/User/UserShow')),
        title: 'TA|Students-view'
      },
      {
        exact: true,
        path: '/contents/applications',
        component: lazy(() => import('./views/Division/DivisionList')),
        title: 'TA|Contents-divisions'
      },
      {
        exact: true,
        path: '/contents/applications/view/:id',
        component: lazy(() => import('./views/Division/ApplicationShow')),
        title: 'TA|Contents-applications-view'
      },
      {
        exact: true,
        path: '/contents/applications/create',
        component: lazy(() => import('./views/Division/DivisionCreate')),
        title: 'TA|Contents-applications-create'
      },
      {
        exact: true,
        path: '/contents/applications/edit/:id',
        component: lazy(() => import('./views/Division/DivisionEdit')),
        title: 'TA|Contents-applications-edit'
      },
      {
        exact: true,
        path: '/contents/offers',
        component: lazy(() => import('./views/Chapter/ChapterList')),
        title: 'TA|Contents-chapters'
      },
      {
        exact: true,
        path: '/contents/offers/create',
        component: lazy(() => import('./views/Chapter/ChapterCreate')),
        title: 'TA|Contents-chapters-create'
      },
      {
        exact: true,
        path: '/contents/offers/edit/:id',
        component: lazy(() => import('./views/Chapter/ChapterEdit')),
        title: 'TA|Contents-chapters-edit'
      },
      {
        exact: true,
        path: '/contents/offers/view/:id',
        component: lazy(() => import('./views/Chapter/ChapterShow')),
        title: 'TA|Contents-chapters-view'
      },
      {
        exact: true,
        path: '/sections/section',
        component: lazy(() => import('./views/Sections/SectionList')),
        title: 'Section'
      },
      {
        exact: true,
        path: '/sections/section/view/:id',
        component: lazy(() => import('./views/Sections/SectionShow')),
        title: 'Section Show'
      },
      {
        exact: true,
        path: '/sections/section/edit/:id',
        component: lazy(() => import('./views/Sections/SectionEdit')),
        title: 'Section Edit'
      },
      {
        exact: true,
        path: '/sections/section/create',
        component: lazy(() => import('./views/Sections/SectionCreate')),
        title: 'Create Section'
      },
      {
        exact: true,
        path: '/subjects/subject/',
        component: lazy(() => import('./views/Subjects/SubjectList')),
        title: 'Subjects'
      },
      {
        exact: true,
        path: '/subjects/subject/edit/:id',
        component: lazy(() => import('./views/Subjects/SubjectEdit')),
        title: 'Subjects'
      },
      {
        exact: true,
        path: '/subjects/subject/create',
        component: lazy(() => import('./views/Subjects/SubjectCreate')),
        title: 'Subjects'
      },
      {
        exact: true,
        path: '/subjects/subject/view/:id',
        component: lazy(() => import('./views/Subjects/SubjectShow')),
        title: 'Subjects'
      },
      {
        exact: true,
        path: '/levels/level/',
        component: lazy(() => import('./views/Level/LevelList')),
        title: 'Levels'
      },
      {
        exact: true,
        path: '/levels/level/view/:id',
        component: lazy(() => import('./views/Level/LevelShow')),
        title: 'Level'
      },
      {
        exact: true,
        path: '/levels/level/edit/:id',
        component: lazy(() => import('./views/Level/LevelEdit')),
        title: 'Level Edit'
      },
      {
        exact: true,
        path: '/levels/level/create',
        component: lazy(() => import('./views/Level/LevelCreate')),
        title: 'Create Level'
      },
      {
        exact: true,
        path: '/dashboard',
        component: () => <Redirect to='/' />,
        title: 'TA|Dashboard'
      },
    ],
  },
  {
    path: '*',
    layout: MinimalLayout,
    routes: [
      // {
      //   exact: true,
      //   path: '/',
      //   component: HomeView
      // },
      {
        component: () => <Redirect to='/404' />,
        title: 'TA|Not found'

      },
    ],
  },
];

export default routes;
