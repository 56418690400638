import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api_get } from "utils/Api";

const initialState = {
  appsStatsStatus: "idle",
  appsStatsError: null,
  appsStats: [],
  usersStatsStatus: "idle",
  usersStatsError: null,
  usersStats: [],

};

export const fetchApplicationsStats = createAsyncThunk(
  "stats/getstats",
  async () => {
    let data;
    try {
      const response = await api_get(`api/v1/applications/stats`);
      data = await response;
      if (response.status === "success") {
        return data;
      }
      throw new Error(response.message);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const fetchUsersStats = createAsyncThunk(
  "stats/getuserStats",
  async () => {
    let data;
    try {
      const response = await api_get(`api/v1/users/stats`);
      data = await response;
      if (response.status === "success") {
        return data;
      }
      throw new Error(response.message);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);


const slice = createSlice({
  name: "stats",
  initialState,
  reducers: {},

  extraReducers: {
    [fetchApplicationsStats.pending]: (state) => {
      state.appsStatsStatus = "loading";
    },
    [fetchApplicationsStats.fulfilled]: (state, action) => {
      state.appsStatsStatus = "succeeded";
      state.appsStats = action.payload;
    },
    [fetchApplicationsStats.rejected]: (state, action) => {
      state.appsStatsStatus = "failed";
      state.error = action.payload;
    },
    [fetchUsersStats.pending]: (state) => {
      state.usersStatsStatus = "loading";
    },
    [fetchUsersStats.fulfilled]: (state, action) => {
      state.usersStatsStatus = "succeeded";
      state.usersStats = action.payload;
    },
    [fetchUsersStats.rejected]: (state, action) => {
      state.usersStatsStatus = "failed";
      state.error = action.payload;
    },
  },
});

export const reducer = slice.reducer;
export default slice;
