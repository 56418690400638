import axios from 'axios';

export const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  };

const myAxiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL ,headers});
export const token = localStorage.getItem('token');
myAxiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);
export default myAxiosInstance;