import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import Toolbar from "../../components/Toolbar/Toolbar";
import Table from "../../components/Table/Table";
import Collapse from "@material-ui/core/Collapse";
import Columns from "../../components/Columns";
import Filter from "../../components/Filter/Filter";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchApplications,
  saveFilteredValuesApplications,
} from "redux/slices/applications";
import {changeDeleteApplicationStatus} from "redux/slices/applications"
import SnackBar from "components/SnackBar";


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

const columnsList = [
  { accessor: "_id", name: "id", label: "Id", width: "7%", show: true },
  { accessor: "firstName", label: "Name", width: "10%", show: true },
  { accessor: "lastName", label: "lastName", width: "10%", show: true },
  {
    accessor: "section",
    label: "Section",
    width: "7%",
    show: true,
    type: "fieldValue",
    sortable: true,
  },
  {
    accessor: "level",
    label: "Level",
    width: "9%",
    show: true,
    type: "fieldValue",
  },
  {
    accessor: "status",
    label: "Status",
    width: "8%",
    show: true,
    type: "status",
  },
  {
    accessor: "email",
    label: "Email",
    width: "12%",
    show: true,
    type: "fieldValue",
  },
  {
    accessor: "phoneNumber",
    label: "phone",
    width: "7%",
    show: true,
    type: "fieldValue",
  },
  {
    accessor: "createdAt",
    type: "date",
    width: "8%",
    label: "Created At",
    show: true,
    sortable: true,
  },
  {
    accessor: "active",
    type: "boolean",
    width: "7%",
    label: "active",
    show: true,
    sortable: true,
  },
];
const fieldSearchable = ["firstName", "_id", "lastName", "email"];
const fieldFilterable = [
  { name: "createdAt", label: "Created At", type: "dateRange" },
  {
    name: "status",
    label: "Status",
    type: "dropdown",
    options: [],
  },
  { name: "active", label: "Active", type: "boolean" },
];
const DivisionList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [search, searchChange] = useState("");
  const [filters, filtersChange] = useState({});
  const [columns, columnsChange] = useState(columnsList);
  const [sort, sortChange] = useState({ accessor: "", order: "" });
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [columnsOpen, setColumnsOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [data, setData] = useState([]);
  const [message, setAlertMessage] = useState("");
  const [severity, setAlertSeverity] = useState("");
  const [open, setOpen] = useState(false);

  const {deletestatus}= useSelector((state) => state?.applications)
  const { applications, meta, filteredValueApplications } = useSelector(
    (state) => state?.applications
  );

  const status = [
    { _id: "Applied", name: "Applied" },
    { _id: "First Validation", name: "First Validation" },
    { _id: "Second Validation", name: "Second Validation" },
    { _id: "Accepted", name: "Accepted" },
    { _id: "Rejected", name: "Rejected" },
  ];
  if (status) {
    fieldFilterable.filter((field) =>
      field.name === "status" ? (field.options = status) : ""
    );
  }
  useEffect(() => {
    if (filteredValueApplications === null) {
      filtersChange({});
      dispatch(saveFilteredValuesApplications([]));
    }
  }, [filteredValueApplications, dispatch, filters]);

  useEffect(() => {
    if (search.length < 1 && Object.keys(filters).length < 1) {
      dispatch(fetchApplications({ page: page + 1, limit: rowsPerPage }));
    }
  }, [dispatch, filters, page, search, rowsPerPage]);

  useEffect(() => {
    if (search.length > 0 && Object.keys(filters).length < 1) {
      const timeOut = setTimeout(() => {
        dispatch(
          fetchApplications({
            page: page + 1,
            limit: rowsPerPage,
            search: search,
          })
        );
      }, 300);
      return () => {
        clearTimeout(timeOut);
      };
    }
  }, [dispatch, page, filters, search, rowsPerPage]);

  const toggleFilters = () => {
    setFiltersOpen(!filtersOpen);
  };
  const toggleColumns = () => {
    setColumnsOpen(!columnsOpen);
  };

  // sort function
  const handleSortChange = (accessor) => {
    sortChange({
      accessor: accessor,
      order: sort.order === "asc" ? "desc" : "asc",
    });
  };

  useEffect(() => {
    setData(applications);
  }, [applications]);

  useEffect(() => {
    if (search.length > 0) {
      setPage(0);
    }
  }, [search]);

  useEffect(() => {
    if (Object.keys(filters).length > 0) {
      setPage(0);
    }
  }, [filters]);

  useEffect(() => {
    if (deletestatus === "succeeded") {
      handleSnackBar("success", "Application Deleted Successfully")
      dispatch(changeDeleteApplicationStatus("init"))
    }
  }, [deletestatus, dispatch])

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const initializeData = (num, rows) => {
    setPage(num);
    setRowsPerPage(rows);
  };

  const handleSnackBar = (type, msg) => {
    setOpen(true)
    setAlertSeverity(type)
    setAlertMessage(msg)
  }
  
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Toolbar
        fieldSearchable={fieldSearchable}
        toggleFilters={toggleFilters}
        toggleColumns={toggleColumns}
        searchChange={searchChange}
        pageLink={"/contents/applications"}
        searchMessage={"Search ( Name, lastName, email )"}
      />
      <div className={classes.content}>
        <Collapse in={columnsOpen}>
          <Columns columnsChange={columnsChange} columns={columns} />
        </Collapse>
        <Collapse in={filtersOpen}>
          <Filter
            fields={fieldFilterable}
            FilterType={"applications"}
            values={filters}
            filtersChange={filtersChange}
            page={page + 1}
            search={search}
            rowsPerPage={rowsPerPage}
            initializeData={initializeData}
          />
        </Collapse>
        <Table
          columns={columns}
          data={data}
          meta={meta}
          handleSortChange={handleSortChange}
          sort={sort}
          handlePageChange={handleChangePage}
          tableService={"applications"}
          page={page}
          rowsPerPage={rowsPerPage}
          handleRowsPerPageChange={handleChangeRowsPerPage}
          pageLink={"/contents/applications"}
        />
      </div>
      <SnackBar
          open={open}
          message={message}
          severity={severity}
          handleClose={handleClose}
        />
    </div>
  );
};

export default DivisionList;
