import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Card,
  CardHeader,
  Divider,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
  fontWeightMedium: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

const BasicsInfo = ({ application, className, ...rest }) => {
  const PF = process.env.REACT_APP_PF_DOWNLOAD;
  const picturePath = (firstPath) => {
    const lastPartOfPath = firstPath?.split("/")[2];
    const str = PF + lastPartOfPath;
    return str;
  };
  const FormatDate = (date) => {
    date = Date(date);
    let newDate = date?.split("GMT")[0];
    return newDate;
  };
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title="Application info" />
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell
              className={classes.fontWeightMedium}
              style={{ display: "flex", justifyContent: "right" }}
            >
              <div
                style={{
                  display: "flex",
                  width: 60,
                  height: 60,
                  borderRadius: "50%",
                }}
              >
                <img
                  src={picturePath(application?.profilePicture)}
                  alt="avatar"
                  style={{
                    width: 60,
                    height: 60,
                    objectFit: "cover",
                    borderRadius: "50%",
                  }}
                />
              </div>
            </TableCell>
            <TableCell>
              <div>
                <span>{`${application?.firstName} ${application?.lastName}`}</span>
              </div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.fontWeightMedium}>ID</TableCell>
            <TableCell>
              <Typography variant="body2" color="textSecondary">
                {application?._id}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.fontWeightMedium}>Section</TableCell>
            <TableCell>
              <Typography variant="body2" color="textSecondary">
                {application?.section}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.fontWeightMedium}>City</TableCell>
            <TableCell>
              <Typography variant="body2" color="textSecondary">
                {application?.city}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.fontWeightMedium}>Offer</TableCell>
            <TableCell>
              <Typography variant="body2" color="textSecondary">
                {application?.offer?.Name}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.fontWeightMedium}>
              Created At
            </TableCell>
            <TableCell>
              <Typography variant="body2" color="textSecondary">
                {FormatDate(application?.createdAt)}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.fontWeightMedium}>Email</TableCell>
            <TableCell>
              <Typography variant="body2" color="textSecondary">
                {application?.email}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.fontWeightMedium}>
              Phone Number
            </TableCell>
            <TableCell>
              <Typography variant="body2" color="textSecondary">
                {application?.phoneNumber}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
};

BasicsInfo.propTypes = {
  className: PropTypes.string,
  chapter: PropTypes.object.isRequired,
};

export default BasicsInfo;
