import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";

import {
  Budget,
  TotalUsers,
  TasksProgress,
  LatestSales,
  UsersByDevice,
  SectionsCharts,
} from "./components";
import {
  fetchApplicationsStats,
  fetchUsersStats,
} from "redux/slices/statistics";
import { useDispatch, useSelector } from "react-redux";
import Loading from "components/Loading/Loading";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    minHeight: "800px",
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const [ShowSections, setShowSections] = useState(true);
  const dispatch = useDispatch();
  const {
    appsStats,
    appsStatsStatus,
    usersStatsStatus,
    usersStats,
  } = useSelector((state) => state.stats);
  useEffect(() => {
    if (appsStatsStatus === "idle" || usersStatsStatus === "idle") {
      dispatch(fetchApplicationsStats());
      dispatch(fetchUsersStats());
    }
  }, [dispatch, appsStatsStatus, usersStatsStatus]);

  if (
    appsStatsStatus === "loading" ||
    appsStatsStatus === "idle" ||
    usersStatsStatus === "loading" ||
    usersStatsStatus === "idle"
  ) {
    return <Loading />;
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item lg={3} sm={12} xl={3} xs={12}>
          <Budget
            total={appsStats.total}
            newApps={appsStats?.dateAndNumberOfApplications?.[0]}
          />
        </Grid>
        <Grid item lg={3} sm={12} xl={3} xs={12}>
          <TotalUsers
            total={usersStats?.total}
            newUsers={usersStats?.dateAndNumberOfUsers?.[0]}
          />
        </Grid>
        <Grid item lg={6} sm={12} xl={6} xs={12}>
          <TasksProgress users={usersStats?.usersForEachGender} />
        </Grid>
        <Grid item lg={8} md={12} xl={9} xs={12}>
          {ShowSections ? (
            <LatestSales
              users={usersStats?.dateAndNumberOfUsers}
              apps={appsStats?.dateAndNumberOfApplications}
              setShowSections={setShowSections}
            />
          ) : (
            <SectionsCharts
              users={usersStats.usersPerSections}
              apps={appsStats.applicationsPerSection}
              setShowSections={setShowSections}
            />
          )}
        </Grid>
        <Grid item lg={4} md={12} xl={3} xs={12}>
          <UsersByDevice apps={appsStats?.applicationsPerStatus} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
