import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Form } from "./components";
import SimpleToolbar from "../../components/Toolbar/SimpleToolbar";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { fetchOneOffer } from "redux/slices/offers";
import { useDispatch, useSelector } from "react-redux";
import { LinearProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

const ChapterEdit = (props) => {
  const dispatch = useDispatch();
  const { Link, pageLink, title, ...rest } = props;
  const classes = useStyles();
  const { id } = useParams();

  useEffect(() => {
    dispatch(fetchOneOffer(id));
  }, [id, dispatch]);

  const { oneOffer, oneOfferStatus } = useSelector((state) => state.offers);
  if (oneOfferStatus === "idle" || oneOfferStatus === "loading") {
    return <LinearProgress />;
  }
  return (
    <div className={classes.root}>
      <SimpleToolbar
        Link={Link}
        pageLink={"/contents/offers"}
        title={"Offers"}
      />
      <div className={classes.content}>
        <Form edit={true} data={oneOffer} offerStatus={oneOfferStatus} />
      </div>
    </div>
  );
};

ChapterEdit.propTypes = {
  user: PropTypes.object,
};

export default ChapterEdit;
