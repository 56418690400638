import React, {useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles,useTheme } from '@material-ui/styles';
import {AppBar, Toolbar, Badge, Hidden, IconButton} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import { logout } from "../../../../utils/Storage";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
  

}));

const Topbar = (props) => {
  const {className, onSidebarOpen, ...rest} = props;
  const theme = useTheme();
  const classes = useStyles();

  const [notifications] = useState([]);

  const logOutAdmin = () => {
    logout();
  };
  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar >
        <RouterLink to="/">
          <img
            alt="Logo"
            src="/images/logos/logo--white.svg"
          />
        </RouterLink>
        <Hidden >
          <IconButton style={{marginLeft:'45px'}}
            color="inherit"
            onClick={onSidebarOpen}
          >
            <MenuIcon  />
       
          </IconButton>
        </Hidden>
        <div className={classes.flexGrow} />
        <Hidden mdDown>
          <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>

        </Hidden>
        <IconButton
          className={classes.signOutButton}
          color="inherit"
          onClick={logOutAdmin}
        >
          <ExitToAppIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
};

export default Topbar;
