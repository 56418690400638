// @flow
import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Bar } from "react-chartjs-2";
import { makeStyles } from "@material-ui/styles";
import palette from "theme/palette";

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
} from "@material-ui/core";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { options } from "./chart";

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 500,
    position: "relative",
  },
  actions: {
    justifyContent: "flex-end",
  },
}));

type Props = {
  className: string,
};
const LatestSales = (props: Props): React$Element<any> => {
  const { className, apps, users,setShowSections,...rest } = props;
  const classes = useStyles();
  let lastSevenDays = apps?.map((el) => {
    const arrayOfDate = new Date(el.weekDay).toString().split(" ");
    return `${arrayOfDate[1]} ${arrayOfDate[2]}, ${arrayOfDate[3]}`;
  });
  let getEntityNumbers = (arr, acc) => {
    let arrayOfNumbers = arr?.map((el) => el[acc]);
    return arrayOfNumbers?.reverse()
  };
  const data = {
    labels: lastSevenDays?.reverse(),
    datasets: [
      {
        label: "Users",
        backgroundColor: palette.primary.main,
        data: getEntityNumbers(users, "numberOfUsers"),
      },
      {
        label: "Applications",
        backgroundColor: palette.neutral,
        data: getEntityNumbers(apps, "numberOfapplications"),
      },
    ],
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        action={
          <Button size="small" variant="text">
            {/* Last 7 days <ArrowDropDownIcon /> */}
            Last 7 days
          </Button>
        }
        title="Latest Interactions( Applications, Users )"
      />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          <Bar data={data} options={options} />
        </div>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        <Button color="primary" size="small" variant="text" onClick={()=> setShowSections(false)}>
          See Sections Charts <ArrowRightIcon />
        </Button>
      </CardActions>
    </Card>
  );
};

LatestSales.propTypes = {
  className: PropTypes.string,
};

export default LatestSales;
