import React, { useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Card,
  CardHeader,
  Divider,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Button,
} from "@material-ui/core";

import DownloadIcon from "@mui/icons-material/Download";
import { saveAs } from "file-saver";
import { useSelector } from "react-redux";
import Loading from "components/Loading/Loading";
const useStyles = makeStyles((theme) => ({
  root: {},
  fontWeightMedium: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

const AdditionalInfo = ({ application, className, ...rest }) => {
  const PF = process.env.REACT_APP_PF_DOWNLOAD;
  const downloadFile = (blob, name) => {
    const FileToDownload = blob?.split("/")[2];
    let FileExtension;
    if (FileToDownload?.includes("document")) {
      FileExtension = "doc";
    } else {
      FileExtension = FileToDownload?.split(".")[1];
    }
    saveAs(
      `${PF}${FileToDownload}`,
      `${application.firstName}-${name}.${FileExtension}`
    );
  };
  let status = useSelector((state) => state.applications.status);

  const classes = useStyles();

  if (status === "loading") {
    return <Loading />;
  }
  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title="Application info" />
      <Divider />
      <Table>
        <TableRow>
          <TableCell className={classes.fontWeightMedium}>Status</TableCell>
          <TableCell>
            <Typography variant="body2" color="textSecondary">
              {application?.status}
            </Typography>
          </TableCell>
        </TableRow>
        <TableBody>
          {application?.subjects && (
            <>
              {application?.subjects?.map((subj, index) => (
                <TableRow key={index}>
                  <TableCell className={classes.fontWeightMedium}>
                    {subj.subject.name}
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color="textSecondary">
                      {subj.mark}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </>
          )}
          <TableRow>
            <TableCell className={classes.fontWeightMedium}>Resume</TableCell>
            <TableCell>
              <Typography variant="body2" color="textSecondary">
                <Button
                  variant="outlined"
                  onClick={() => downloadFile(application?.resume, "resume")}
                  endIcon={<DownloadIcon />}
                >
                  Resume
                </Button>
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.fontWeightMedium}>
              motivational Letter
            </TableCell>
            <TableCell>
              <Typography variant="body2" color="textSecondary">
                <Button
                  variant="outlined"
                  onClick={() =>
                    downloadFile(
                      application?.motivationalLetter,
                      "motivationalLetter"
                    )
                  }
                  endIcon={<DownloadIcon />}
                >
                  Motivational Letter
                </Button>
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.fontWeightMedium}>
              transcript
            </TableCell>
            <TableCell>
              <Typography variant="body2" color="textSecondary">
                <Button
                  variant="outlined"
                  onClick={() =>
                    downloadFile(application?.transcript, "transcript")
                  }
                  endIcon={<DownloadIcon />}
                >
                  Transcript
                </Button>
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
};

AdditionalInfo.propTypes = {
  className: PropTypes.string,
  chapter: PropTypes.object.isRequired,
};

export default AdditionalInfo;
