import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Grid, makeStyles } from '@material-ui/core';
import BasicsInfo from './BasicsInfo';
import AditionalInfo from './AdditionalInfo';

const useStyles = makeStyles(() => ({
  root: {},
}));

const Details = ({
  application,
  className,
  ...rest
}) => {


  const classes = useStyles();
  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      spacing={3}
      {...rest}
    >
      <Grid
        item
        lg={4}
        md={6}
        xl={4}
        xs={12}
      >
        <BasicsInfo application={application}/>
      </Grid>
      <Grid
        item
        lg={8}
        md={8}
        xl={8}
        xs={12}
      >
        <AditionalInfo application={application}/>
      </Grid>
    </Grid>
  );
};

Details.propTypes = {
  className: PropTypes.string,
  chapter: PropTypes.object.isRequired,
};

export default Details;
