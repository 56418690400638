import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
  Box,
  TextField,
  Button,
  Typography,
} from "@material-ui/core";
import SendIcon from "@mui/icons-material/Send";
import useIsMountedRef from "hooks/useIsMountedRef";

import { useDispatch, useSelector } from "react-redux";
import Loading from "components/Loading/Loading";
import { Formik } from "formik";
import * as Yup from "yup";
import { createNotification } from "redux/slices/notifications";
import SnackBar from "components/SnackBar";
import LoadingApi from "components/LoadingApi/LoadingApi";

const useStyles = makeStyles((theme) => ({
  root: {},
  fontWeightMedium: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

function Notify({ user, className, ...rest }) {
  const dispatch = useDispatch();
  const isMountedRef = useIsMountedRef();
  const [message, setAlertMessage] = useState("");
  const [severity, setAlertSeverity] = useState("");
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);

  const handleSnackbar = (type, msg) => {
    setOpen(true);
    setAlertMessage(msg);
    setAlertSeverity(type);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseLoading = () => {
    setLoading(false);
  };

  const classes = useStyles();
  return (
    <Formik
      initialValues={{ title: "", content: "" }}
      validationSchema={Yup.object().shape({
        title: Yup.string()
          .min(5)
          .required(),
        content: Yup.string()
          .min(5)
          .required(),
      })}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting, resetForm }
      ) => {
        try {
          if (isMountedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);
            values.to = user;
            dispatch(createNotification(values)).then((res) => {
              if (res.meta.requestStatus === "rejected") {
                handleSnackbar("error", res.error.message);
              } else {
                handleSnackbar("success", "Offer updated successfully");
                values.title = "";
                values.content = "";
                console.log("%cNotify.js line:81 values", "color: #007acc;", {
                  values,
                });
              }
            });
          }
        } catch (err) {
          if (isMountedRef.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => {
        return (
          <>
            <form
              autoComplete="off"
              noValidate
              onSubmit={handleSubmit}
              {...rest}
            >
              <Card className={clsx(classes.root, className)} {...rest}>
                <CardHeader title="Notify This User" />
                <Divider />
                <CardContent>
                  <Box mt={2} mb={2}>
                    <Typography variant="subtitle2" color="textSecondary">
                      Notify this user with the new updates
                    </Typography>
                  </Box>
                  <TextField
                    fullWidth
                    label="Notification subject"
                    name="title"
                    variant="outlined"
                    size="small"
                    onChange={handleChange}
                    error={errors?.title && touched.title}
                    helperText={
                      errors?.title && touched.title ? errors?.title : ""
                    }
                  />
                  <Box mt={2} mb={2}>
                    <TextField
                      fullWidth
                      name="content"
                      label="Notification body"
                      multiline
                      mt={2}
                      variant="outlined"
                      rows={18}
                      onChange={handleChange}
                      error={errors?.content && touched.content}
                      helperText={
                        errors?.content && touched.content
                          ? errors?.content
                          : ""
                      }
                    />
                  </Box>
                  <Box mt={2} style={{ textAlign: "end" }}>
                    <Button
                      variant="contained"
                      endIcon={<SendIcon />}
                      color={isSubmitting ? "secondary" : "primary"}
                      disabled={isSubmitting}
                      type="submit"
                    >
                      Notify
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </form>
            <SnackBar
              open={open}
              message={message}
              severity={severity}
              handleClose={handleClose}
            />
            <LoadingApi open={loading} onClick={handleCloseLoading} />
          </>
        );
      }}
    </Formik>
  );
}

export default Notify;
