// @flow
import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Bar } from "react-chartjs-2";
import { makeStyles } from "@material-ui/styles";
import palette from "theme/palette";

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
} from "@material-ui/core";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import { options } from "./chart";

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 500,
    position: "relative",
  },
  actions: {
    justifyContent: "flex-end",
  },
}));

type Props = {
  className: string,
};
const SectionsCharts = (props: Props): React$Element<any> => {
  const { className, apps, users,setShowSections, ...rest } = props;
  const classes = useStyles();
  let keys = [];
  let values = [];
  let userValues = [];
  if (apps && users) {
    for (const [key, value] of Object.entries(apps)) {
      keys.push(key);
      values.push(value.total);
    }
    for (const  value of Object.values(users)) {
        userValues.push(value.total);
      }
  }

  const data = {
    labels: keys,
    datasets: [
      {
        label: "Users",
        backgroundColor: palette.primary.main,
        data: userValues,
      },
      {
        label: "Applications",
        backgroundColor: palette.neutral,
        data: values,
      },
    ],
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        action={
          <Button size="small" variant="text">
            Users And Applications Per sections
          </Button>
        }
        title="Latest Interactions( Applications, Users )"
      />
      <Divider />
      <CardContent style={{overflow: "hidden"}}>
        <div className={classes.chartContainer}>
          <Bar data={data} options={options} style={{width: 1500 }}/>
        </div>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        <Button color="primary" size="small" variant="text" onClick={()=> setShowSections(true)}>
        <ArrowLeftIcon /> See Last 7 Days Chart
        </Button>
      </CardActions>
    </Card>
  );
};

SectionsCharts.propTypes = {
  className: PropTypes.string,
};

export default SectionsCharts;