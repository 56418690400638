import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Divider,
  makeStyles,
  Tab,
  Tabs,
} from "@material-ui/core";
import Page from "../../components/Page";
import ChapterInfo from "./ChapterShow/Index";
import { useParams } from "react-router-dom";
import SimpleToolbar from "../../components/Toolbar/SimpleToolbar";
import { useDispatch, useSelector } from "react-redux";
import { fetchOneOffer } from "redux/slices/offers";
import { fetchApplications } from "redux/slices/applications";
import Loading from "components/Loading/Loading";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

const UserShow = (props) => {
  const { Link, pageLink, title, ...rest } = props;
  const { id } = useParams();
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState("details");
  const dispatch = useDispatch();
  const tabs = [{ value: "details", label: "offer details" }];

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  useEffect(() => {
    dispatch(fetchOneOffer(id));
  }, [dispatch, id]);
  const { oneOffer, oneOfferStatus } = useSelector((state) => state.offers);

  useEffect(() => {
    if (oneOffer) {
      dispatch(fetchApplications({ offer: oneOffer._id }));
    }
  }, [oneOffer, dispatch]);
  const { applications, status } = useSelector((state) => state.applications);
  
  if (oneOfferStatus === "loading" || status === "loading") {
    return <Loading />;
  }
  return (
    <div>
      <SimpleToolbar
        Link={Link}
        pageLink={"/contents/offers"}
        title={"Offers"}
      />
      <Page className={classes.root} title="Offer Details">
        <Container maxWidth={false}>
          <Box mt={3}>
            <Tabs
              onChange={handleTabsChange}
              scrollButtons="auto"
              value={currentTab}
              variant="scrollable"
              textColor="secondary"
            >
              {tabs.map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </Tabs>
          </Box>
          <Divider />
          <Box mt={3}>
            {currentTab === "details" && (
              <ChapterInfo offer={oneOffer} OfferApplications={applications} />
            )}
          </Box>
        </Container>
      </Page>
    </div>
  );
};

export default UserShow;
