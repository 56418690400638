import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api_get, api_post } from "utils/Api";
import { openSnackBar } from "./snackbar";
const initialState = {
  notifications: [],
  createStatus: "idle",
  createError: null,
  createdNotif: ''
};

export const getNotifications = createAsyncThunk(
    "notifications/getNotifications",
    async (id) => {
        let data;
        try {
            const response = await api_get(`api/v1/notifications`);
            data = await response.data;
            if (response.status ==="success") {
                return data;
            }
            throw new Error(response.message);
        } catch (err) {
            return Promise.reject(err.message ? err.message : data?.message);
        }
    }
);
export const createNotification = createAsyncThunk(
  "notifications/createNotification",
  async (notif, thunkAPI) => {
    let data;
    try {
      const response = await api_post(`api/v1/notifications/create`, notif);
      data = await response.data;
      if ((response.status === "success")) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: "notifications",
  initialState,
  reducers: {},
  extraReducers: {
    [createNotification.pending]: (state) => {
      state.createStatus = "loading";
    },
    [createNotification.fulfilled]: (state, action) => {
      state.createStatus = "succeeded";
      state.notifications.push(action.payload.data);
    },
    [createNotification.rejected]: (state, action) => {
      state.createStatus = "failed";
      state.createError = action.payload.message;
    },
  },
});
export const reducer = slice.reducer;
export const { closePopUp, setPopUp } = slice.actions;

export default slice;
