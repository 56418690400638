import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api_delete, api_get,  api_post,  api_put } from "utils/Api";


const initialState = {
  subjects: [],
  meta:[],
  status: "idle",
  error: null,
  newSubject:[],
  statusAddNewSubject:"init",
  errorAddNewSubject:null,
  updatedSubject:"",
  statusUpdatedSubject:"init",
  errorUpdateSubject:null,
  deletedSubject:[],
  statusDeletedSubject:"init",
  errorDeletedSubject:null,
  subject:"",
  errorSubject:null,
  statusSelectedSubject:"init",
  filteredValue:[],
};

export const fetchAllSubjects= createAsyncThunk("subjects/fetchAllSubjects", async (queryParams) => {
  let data;
     if (queryParams){
        if(Object.keys(queryParams).includes("createdAt") ){
          const values=queryParams["createdAt"]
          delete queryParams.createdAt
          queryParams['createdAt[gte]']=values[0]+"T00:00:00.000Z"
          queryParams['createdAt[lte]']=values[1]+"T23:59:59.999Z"
        console.log("front",queryParams)
        }
     }

    try {
      const response = await api_get(`api/v1/subjects/`, queryParams);
      data = await response;
      if (response.status ==="success") {
      return data;
      }
      throw new Error(response.message);
    } 
    catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
});

export const fetchOneSubject= createAsyncThunk("subjects/fetchOneSubject", async (subjectId) => {
  let id=subjectId
  let data;
    try {
      const response = await api_get(`api/v1/subjects/${id}`, );
      data = await response.data;
      if (response.status ==="success") {
  
      return data;
      }
      throw new Error(response.message);
    } 
    catch (err) {
      console.log("err in fetchOneSection",err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
});

export const editSubject=createAsyncThunk("subjects/editSubject",async(body,thunkAPI)=>{ 
const Data=body
const id=Data?.id
delete Data.id

   let data;
   try {
     const response = await api_put(`api/v1/subjects/${id}/`,Data);
     data = await response.data;
     console.log(response)
      if (response.status === "success") {
      thunkAPI.dispatch(fetchOneSubject(id))
      thunkAPI.dispatch(fetchAllSubjects());
       return data;
      }
    throw new Error(response.message);
  }
   catch (err) {
    console.log("ERROR EDIT SECTION ",err)
    return Promise.reject(err.message ? err.message : data?.message);
  }

})

export const addNewSubject=createAsyncThunk("subjects/addNewSubject",async(body,thunkAPI)=>{
  let Data=body
  let data; 
  console.log("DATA FROM SLICE ",Data)

  try{ 
    const response=await api_post(`api/v1/subjects/`,Data)  
    data=await response.data;
    if(response.status==="success"){
      thunkAPI.dispatch(fetchAllSubjects())
      return data
    }
    throw new Error(response.message);
  }
  catch(err){
    console.log("ERROR REQUEST ADD NEW SECTION ",err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
})

export const deleteOneSubject=createAsyncThunk("subjects/deleteOneSubject",async(body,thunkAPI)=>{ 
  const id=body.id
  delete body.id 
  let queries=body 
  let data;
     try {
       const response = await api_delete(`api/v1/subjects/${id}`);
       data = await response.data;
        if (response.status ==="success") {
         thunkAPI.dispatch(fetchAllSubjects(queries));
         return data;
        }
      throw new Error(response.message);
    }
     catch (err) {
      console.log(err)
      return Promise.reject(err.message ? err.message : data?.message);
    }
})

const slice = createSlice({
  name: "subjects",
  initialState,
  reducers: {
    changeStatusAddNewSubject:(state,action)=>{
      state.statusAddNewSubject="init"
      state.errorAddNewSubject=null
      state.newSubject=""      
    },
    changeStatusEditSubject:(state,action)=>{
      state.statusUpdatedSubject="init"
      state.errorUpdateSubject=null
      state.updatedSubject=""
    },
    changeStatusDeletedSubject:(state,action)=>{
      state.statusDeletedSubject="init"
      state.errorDeletedSubject=null
      state.deletedSubject=""
    }, 
    removeSelectedSubject:(state,action)=>{
      state.statusSelectedSubject="init"
      state.errorSubject=null
      state.subject=""
    },
    saveFilteredValuesSubjects:(state,action)=>{
      state.filteredValue=action.payload
    }
  },

  extraReducers: {
    [fetchAllSubjects.pending]: (state) => {
      state.status = "loading";
    },
    [fetchAllSubjects.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.subjects = action.payload.data;
      state.meta=action.payload.meta
    },
    [fetchAllSubjects.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [fetchOneSubject.pending]: (state) => {
      state.statusSelectedSubject = "loading";
    },
    [fetchOneSubject.fulfilled]: (state, action) => {
      state.statusSelectedSubject = "succeeded";
      state.subject = action.payload;
    },
    [fetchOneSubject.rejected]: (state, action) => {
      state.statusSelectedSubject = "failed";
      state.errorSubject= action.payload;
    },
    [addNewSubject.pending]: (state) => {
      state.statusAddNewSubject = "loading";
    },
    [addNewSubject.fulfilled]: (state, action) => {
      state.statusAddNewSubject = "succeeded";
      state.newSubject= action.payload;
    },
    [addNewSubject.rejected]: (state, action) => {
      state.statusAddNewSubject = "failed";
      state.errorAddNewSubject = action.error.message;
    },
    [editSubject.pending]: (state) => {
      state.statusUpdatedSubject = "loading";
    },
    [editSubject.fulfilled]: (state, action) => {
      state.statusUpdatedSubject = "succeeded";
      state.updatedSubject = action.payload;
    },
    [editSubject.rejected]: (state, action) => {
      state.statusUpdatedSubject = "failed";
      state.errorUpdateSubject = action.error.message;
    },
    [deleteOneSubject.pending]: (state) => {
      state.statusDeletedSubject = "loading";
    },
    [deleteOneSubject.fulfilled]: (state, action) => {
      state.statusDeletedSubject = "succeeded";
      state.deletedSubject = action.payload;
    },
    [deleteOneSubject.rejected]: (state, action) => {
      state.statusDeletedSubject = "failed";
      state.errorDeletedSubject = action.payload;
    },
  },
});

export const reducer = slice.reducer;
export const {changeStatusEditSubject,changeStatusAddNewSubject, removeSelectedSubject ,saveFilteredValuesSubjects ,changeStatusDeletedSubject} = slice.actions;
export default slice;