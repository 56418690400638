import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api_delete, api_get,  api_post,  api_put } from "utils/Api";

const initialState = {
  levels: [],
  meta:[],
  status: "idle",
  error: null,
  newLevel:[],
  statusAddNewLevel:"init",
  errorAddNewLevel:null,
  updatedLevel:"",
  statusUpdatedLevel:"init",
  errorUpdateLevel:null,
  deletedLevel:[],
  statusDeletedLevel:"init",
  errorDeletedLevel:null,
  level:"",
  errorLevel:null,
  statusSelectedLevel:"init",
  filteredValue:[],
};

export const fetchAllLevels= createAsyncThunk("levels/fetchAllLevels", async (queryParams) => {
  let data;
     if (queryParams){
        if(Object.keys(queryParams).includes("createdAt") ){
          const values=queryParams["createdAt"]
          delete queryParams.createdAt
          queryParams['createdAt[gte]']=values[0]+"T00:00:00.000Z"
          queryParams['createdAt[lte]']=values[1]+"T23:59:59.999Z"
        }
     }

    try {
      const response = await api_get(`api/v1/levels/`, queryParams);
      data = await response;
      if (response.status ==="success") {
      return data;
      }
      throw new Error(response.message);
    } 
    catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
});

export const fetchOneLevel= createAsyncThunk("levels/fetchOneLevel", async (levelId) => {
  let id=levelId
  let data;
    try {
      const response = await api_get(`api/v1/levels/${id}`, );
      data = await response.data;
      if (response.status ==="success") {
  
      return data;
      }
      throw new Error(response.message);
    } 
    catch (err) {
      console.log("err in fetchOneLevel",err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
});

export const editLevel=createAsyncThunk("levels/editSubject",async(body,thunkAPI)=>{ 
const Data=body
const id=Data?.id
delete Data.id

   let data;
   try {
     const response = await api_put(`api/v1/levels/${id}/`,Data);
     data = await response.data;
     console.log(response)
      if (response.status === "success") {
      thunkAPI.dispatch(fetchOneLevel(id))
      thunkAPI.dispatch(fetchAllLevels());
       return data;
      }
    throw new Error(response.message);
  }
   catch (err) {
    console.log("ERROR EDIT LEVEL",err)
    return Promise.reject(err.message ? err.message : data?.message);
  }

})

export const addNewLevel=createAsyncThunk("levels/addNewLevel",async(body,thunkAPI)=>{
  let Data=body
  let data; 

  try{ 
    const response=await api_post(`api/v1/levels/`,Data)  
    data=await response.data;
    if(response.status==="success"){
      thunkAPI.dispatch(fetchAllLevels())
      return data
    }
    throw new Error(response.message);
  }
  catch(err){
    console.log("ERROR REQUEST ADD NEW LEVEL ",err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
})

export const deleteOneLevel=createAsyncThunk("levels/deleteOneLevel",async(body,thunkAPI)=>{ 
  const id=body.id
  delete body.id 
  let queries=body 
  let data;
     try {
       const response = await api_delete(`api/v1/levels/${id}`);
       data = await response.data;
        if (response.status ==="success") {
         thunkAPI.dispatch(fetchAllLevels(queries));
         return data;
        }
      throw new Error(response.message);
    }
     catch (err) {
      console.log("ERROR IN DELETE ONE LEVEL",err)
      return Promise.reject(err.message ? err.message : data?.message);
    }
})

const slice = createSlice({
  name: "levels",
  initialState,
  reducers: {
    changeStatusAddNewLevel:(state,action)=>{
      state.statusAddNewLevel="init"
      state.errorAddNewLevel=null
      state.newLevel=""
    },
    changeStatusEditLevel:(state,action)=>{
      state.statusUpdatedLevel="init"
      state.errorUpdateLevel=null
      state.updatedLevel=""
    },
    changeStatusDeletedLevel:(state,action)=>{
      state.statusDeletedLevel="init"
      state.errorDeletedLevel=null
      state.deletedLevel=""
    },
    removeSelectedLevel:(state,action)=>{
      state.statusSelectedLevel="init"
      state.errorLevel=null
      state.level=""
    },
    saveFilteredValuesLevel:(state,action)=>{
      state.filteredValue=action.payload
    },
  },

  extraReducers: {
    [fetchAllLevels.pending]: (state) => {
      state.status = "loading";
    },
    [fetchAllLevels.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.levels = action.payload.data;
      state.meta=action.payload.meta
    },
    [fetchAllLevels.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [fetchOneLevel.pending]: (state) => {
      state.statusSelectedLevel = "loading";
    },
    [fetchOneLevel.fulfilled]: (state, action) => {
      state.statusSelectedLevel = "succeeded";
      state.level = action.payload;
    },
    [fetchOneLevel.rejected]: (state, action) => {
      state.statusSelectedLevel = "failed";
      state.errorLevel= action.payload;
    },
    [addNewLevel.pending]: (state) => {
      state.statusAddNewLevel = "loading";
    },
    [addNewLevel.fulfilled]: (state, action) => {
      state.statusAddNewLevel = "succeeded";
      state.newLevel= action.payload;
    },
    [addNewLevel.rejected]: (state, action) => {
      state.statusAddNewLevel = "failed";
      state.errorAddNewLevel = action.error.message;
    },
    [editLevel.pending]: (state) => {
      state.statusUpdatedLevel = "loading";
    },
    [editLevel.fulfilled]: (state, action) => {
      state.statusUpdatedLevel = "succeeded";
      state.updatedLevel = action.payload;
    },
    [editLevel.rejected]: (state, action) => {
      state.statusUpdatedLevel = "failed";
      state.errorUpdateLevel = action.error.message;
    },
    [deleteOneLevel.pending]: (state) => {
      state.statusDeletedLevel = "loading";
    },
    [deleteOneLevel.fulfilled]: (state, action) => {
      state.statusDeletedLevel = "succeeded";
      state.deletedLevel = action.payload;
    },
    [deleteOneLevel.rejected]: (state, action) => {
      state.statusDeletedLevel = "failed";
      state.errorDeletedLevel = action.payload;
    },
  },
});

export const reducer = slice.reducer;
export const {changeStatusEditLevel,changeStatusAddNewLevel, removeSelectedLevel ,saveFilteredValuesLevel , changeStatusDeletedLevel} = slice.actions;
export default slice;