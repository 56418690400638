// @flow
import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  NativeSelect,
} from '@material-ui/core';
import Loading from 'components/Loading/Loading';
import { Formik } from 'formik';
import useIsMountedRef from 'hooks/useIsMountedRef';
import * as Yup from "yup";
import { Box } from 'react-feather';
import FormControl from "@material-ui/core/FormControl";
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import Tab from "@material-ui/core/Tab";
import { useDispatch } from 'react-redux';
import { changeStatusEmailUpdate, changeStatusUpdatePassword, changeStatusUpdateUser, editUser, sendEmailUpdateToken, updateUserPassword } from 'redux/slices/usersSlice';
import { useSelector } from 'react-redux';
import SnackBar from 'components/SnackBar';

const useStyles = makeStyles((theme) => ({
  root: {
      width: "100%",
      "& > * + *": {
          marginTop: theme.spacing(2),
      },
      overflow: "visible",
  },
  mtop: {
      marginTop: 24,
  },
  mtop0: {
      marginTop: 0,
  },
  content: {
      padding: 0,
  },
  uploadedFiles: {
      marginTop: 10,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      minWidth: "10px",
    },
  },
}));
type Props = {
  className: string
}

const AccountDetails = (props: Props): React$Element<any> => {
  const {className,user, ...rest} = props;
  const classes = useStyles();
  const dispatch=useDispatch()
  const isMountedRef = useIsMountedRef();
  const [tab, setTab] = useState(1);
  const [message, setAlertMessage] = useState("");
  const [severity, setAlertSeverity] = useState("");
  const [open, setOpen] = useState(false);
 
  const {statusUpdatedUser,errorUpdateUser,statusUpdateUserPassword,
        errorUpdateUserPassword,statusSendEmailUpdateToken,
        errorSendEmailUpdateToken} = useSelector((state) => state?.users);


  const roles=[{"_id":"user","name":"user"},{"_id":"admin","name":"admin"}]
  const genders=[{"_id":"male","name":"male"},{"_id":"female","name":"female"}]

//   useEffect(() => { 
//     if (user){
//      setValues(user)
//     }
//  }, [props]);
 
//   useEffect(()=>{
//     if (statusUpdatedUser==="succeeded" && tab===1){
//       handleSnackBar("success","User Edited Successfully")
//       dispatch(changeStatusUpdateUser())
//     }
//   },[statusUpdatedUser,tab])
 
//   useEffect(() => {
//     if ( errorUpdateUser && tab === 1 ) {
//       handleSnackBar("error", errorUpdateUser);
//     }
//   }, [errorUpdateUser, tab]);

//   useEffect(()=>{
//     if (statusUpdateUserPassword==="succeeded" && tab===2){
//       handleSnackBar("success","Password Changed Successfully")
//       dispatch(changeStatusUpdatePassword())
//       setTab(1)
//     }
//   },[statusUpdateUserPassword,tab])

//   useEffect(() => {
//     if ( errorUpdateUserPassword && tab === 2 ) {
//       handleSnackBar("error", errorUpdateUserPassword);
//     }
//   }, [errorUpdateUserPassword, tab]);

//   useEffect(()=>{
//     if (statusSendEmailUpdateToken==="succeeded" && tab===3 ){
//       handleSnackBar("success","Email Sent Successfully, Check Your New Email")
//       dispatch(changeStatusEmailUpdate())
//       setTab(1)
//     }
//   },[statusSendEmailUpdateToken,tab])
  
  
//   useEffect(() => {
//     if ( statusSendEmailUpdateToken ==="failed" && tab === 3 ) {
//       handleSnackBar("error", errorSendEmailUpdateToken);
//     }
//   }, [statusSendEmailUpdateToken,errorSendEmailUpdateToken, tab]);

  const [values, setValues] = useState({
    name: user.name,
    lastName: user.lastName,
    email: user.email,
    phoneNumber: user.phoneNumber ,
    password: "",
    passwordConfirm: "",
    gender: user.gender ,
    role: user.role 
  });

  useEffect(() => {
    if (errorUpdateUser && tab === 1) {
      handleSnackBar("error", errorUpdateUser);
      dispatch(changeStatusUpdatePassword())
    }
  }, [ errorUpdateUser, tab === 1]);

  useEffect(() => {
    if (errorUpdateUserPassword && tab === 2) {
      handleSnackBar("error", errorUpdateUserPassword);
      dispatch(changeStatusUpdatePassword())
    }
  }, [ errorUpdateUserPassword, tab === 2]);

  useEffect(() => {
    if (errorSendEmailUpdateToken && tab === 3) {
      handleSnackBar("error", errorSendEmailUpdateToken);
      dispatch(changeStatusEmailUpdate())
    }
  }, [ errorSendEmailUpdateToken, tab === 3]);


  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const handleSnackBar = (type, msg) => {
    setOpen(true);
    setAlertSeverity(type);
    setAlertMessage(msg);
  };

  const handleClose = () => {
    setOpen(false);
  };
 

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    > 
    <TabContext value={tab}>

          <div className={classes.header}>
            <TabList
              scrollButtons="auto"
              variant="scrollable"
              className={classes.header}
              onChange={handleChangeTab}
              aria-label="simple tabs example"
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab
                label="Basics"
                value={1}
              />
              <Tab
                label="Password"
                value={2}
              />
               <Tab
                label="Email"
                value={3}
              />
            </TabList>
          </div>

          <TabPanel value={1}>
            <Formik
              initialValues={{
              name:values.name?values.name:"",
              lastName:values.lastName?values.lastName:"",
              phoneNumber: values.phoneNumber ? values.phoneNumber : "",
              gender: values.gender ? values.gender : "",
              role: values.role ? values.role : "",
        }}
          validationSchema={Yup.object().shape({
            name: Yup.string().max(15).required(),
            lastName: Yup.string().max(15).required(),
            phoneNumber: Yup.string().matches(/^\d+$/,"Phone Number Must Contain Only Numbers").length(8),
            gender:  Yup.string() ,
            role: Yup.string() 
          })}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting }
      ) => {
        try {
          if (isMountedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);
            values.id=user._id
            dispatch(editUser(values))
          }
        } 
        catch (err) {
          console.log("FORM FILE , CATCH ERR", err);
          if (isMountedRef.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
         }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form
                  autoComplete="off"
                  noValidate
                  onSubmit={handleSubmit}
                  {...rest}
                >
                  <CardHeader
                    subheader="Update Your Basics Informations"
                  />
                  <Divider />

                  <CardContent>
                    <Grid
                      container
                      spacing={3}
                    >

                    <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          error={Boolean(touched.name && errors.name)}
                          helperText={touched.name && errors.name}
                          fullWidth
                          autoComplete="none"
                          label="First name"
                          name="name"
                          required
                          onBlur={handleBlur}
                          onChange={handleChange}
                          defaultValue={values.name}
                          variant="outlined"
                        />
                    </Grid>
                  
                    <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                    error={Boolean(touched.lastName && errors.lastName)}
                          helperText={touched.lastName && errors.lastName}
                          fullWidth
                          autoComplete="none"
                          label="LastName"
                          name="lastName"
                          required
                          onBlur={handleBlur}
                          onChange={handleChange}
                          defaultValue={values.lastName}
                          variant="outlined"
                        />
                    </Grid>

                    <Grid 
                        item md={3} xs={12}>
                {
                    <FormControl variant="outlined" fullWidth>
                       <InputLabel id="role-select-label">
                            Role
                          </InputLabel>
                          <Select
                            labelId="role-select-label"
                            id="role-select"
                            label="role"
                            name="role"
                            defaultValue={ values.role}
                            error={Boolean(touched.role && errors.role)}
                            helperText={
                              touched.role && errors.role
                                ? errors.role
                                : ""
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}

                          >
                            {roles && roles ? (
                              roles.map((role) => (
                                <MenuItem value={role.name}>
                                  {role.name}
                                </MenuItem>
                              ))) 
                              : 
                              ( <></> )}
                          </Select>
                        </FormControl>
                      }
                    </Grid> 
                        
                    <Grid
                     item md={3} xs={12}>
                    {
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="gender-select-label">
                          Gender
                        </InputLabel>
                        <Select
                          labelId="gender-select-label"
                          id="gender-select"
                          label="gender"
                          name="gender"
                          defaultValue={ values.gender}
                          error={Boolean(touched.gender && errors.gender)}
                          helperText={
                            touched.gender && errors.gender
                              ? errors.gender
                              : ""
                          }
                          onBlur={handleBlur}
                          onChange={handleChange}
                        >
                          {genders && genders ? (
                            genders.map((gender) => (
                              <MenuItem value={gender.name}>
                                {gender.name}
                              </MenuItem>
                            ))
                          ) : (
                            <></>
                          )}
                        </Select>
                      </FormControl>
                     }
                    </Grid>  

                    <Grid  
                    item md={6} xs={12}>
                                  <TextField
                                    error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                                    helperText={touched.phoneNumber && errors.phoneNumber}
                                    fullWidth
                                    inputProps={{
                                      form: {
                                        autocomplete: "off",
                                      },
                                    }}
                                    autoComplete="none"
                                    label="Phone Number"
                                    name="phoneNumber"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    defaultValue={values.phoneNumber}
                                    variant="outlined"
                                  />
                      </Grid>
                    </Grid>
                  </CardContent>

                  <Divider />
                  {errors.submit && (
                    <Box mt={3}>
                      <FormHelperText error>{errors.submit}</FormHelperText>
                    </Box>
                  )}

                  <CardActions>
                    <Button 
                      color="primary"
                      disabled={isSubmitting}
                      variant="contained"
                      type="submit"
                    >
                      Save details
                    </Button>
                  </CardActions>
                </form>
              )}
            </Formik>
          </TabPanel>

          <TabPanel value={2}>
            <Formik
              initialValues={{
                password: "",
                passwordConfirm: "",
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string().min(8)
          .matches(
            /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
            "Password must contain at least 8 characters, one uppercase, one number and one special case character"
          ).required(),
        passwordConfirm:Yup.string().oneOf([Yup.ref("password")]).required(),
      })}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting }
      ) => {
        try {
          if (isMountedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);
            if (values.password && !values.passwordConfirm){
              handleSnackBar("error","Password Confirm Is Required ")
            }
            if (values.password&&values.passwordConfirm){
              values.id=user._id
              dispatch(updateUserPassword(values))
            }
          }
          } 
        catch (err) {
          console.log("FORM FILE , CATCH ERR", err);
          if (isMountedRef.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form
                  autoComplete="off"
                  noValidate
                  onSubmit={handleSubmit}
                  {...rest}
                >
                  <CardHeader
                    subheader="Update Your Password"
                  />
                  <Divider />

                  <CardContent>
                    <Grid
                      container
                      spacing={3}
                    >

   

                    <Grid 
                    item md={6} xs={12}>
                                  <TextField
                                    error={Boolean(touched.password && errors.password)}
                                    helperText={touched.password && errors.password}
                                    fullWidth
                                    inputProps={{
                                      form: {
                                        autocomplete: "off",
                                      },
                                    }}
                                    label="password"
                                    name="password"
                                    autoComplete="none"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    variant="outlined"
                                    type="password"
                                  />
                    </Grid>
                
                    <Grid
                    item md={6} xs={12}>
                                  <TextField
                                    error={Boolean(
                                      touched.passwordConfirm && errors.passwordConfirm
                                    )}
                                    helperText={
                                      touched.passwordConfirm && errors.passwordConfirm
                                    }
                                    fullWidth
                                    inputProps={{
                                      form: {
                                        autocomplete: "off",
                                      },
                                    }}
                                    label="passwordConfirm"
                                    name="passwordConfirm"
                                    autoComplete="none"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    variant="outlined"
                                    type="password"
                                  />
                    </Grid> 

                    </Grid>
                  </CardContent>

                  <Divider />
                  {errors.submit && (
                    <Box mt={3}>
                      <FormHelperText error>{errors.submit}</FormHelperText>
                    </Box>
                  )}

                  <CardActions>
                    <Button 
                      color="primary"
                      disabled={isSubmitting}
                      variant="contained"
                      type="submit"
                    >
                      Save details
                    </Button>
                  </CardActions>
                </form>
              )}
            </Formik>
          </TabPanel>

          <TabPanel value={3}>
            <Formik
              initialValues={{
                email:values.email?values.email:"",
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string().email().required(),
          })}
          onSubmit={async (
            values,
            { setErrors, setStatus, setSubmitting }
          ) => {
          try {
            if (isMountedRef.current) {
              setStatus({ success: true });
              setSubmitting(false);
              values.id=user._id
              dispatch(sendEmailUpdateToken({id:user._id,email:user.email,newEmail:values.email}))
            }
            } 
        catch (err) {
          console.log("FORM FILE , CATCH ERR", err);
          if (isMountedRef.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form
                  autoComplete="off"
                  noValidate
                  onSubmit={handleSubmit}
                  {...rest}
                >
                  <CardHeader
                    subheader="Change Your Email"
                  />
                  <Divider />

                  <CardContent>
                    <Grid
                      container
                      spacing={3}
                    >
      
                    <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                      fullWidth
                      autoComplete="none"
                      label="Email"
                      name="email"
                      required
                      onBlur={handleBlur}
                      onChange={handleChange}
                      defaultValue={values.email}
                      variant="outlined"
                        />
                      </Grid>
                    

                    </Grid>
                  </CardContent>

                  <Divider />
                  {errors.submit && (
                    <Box mt={3}>
                      <FormHelperText error>{errors.submit}</FormHelperText>
                    </Box>
                  )}

                  <CardActions>
                    <Button 
                      color="primary"
                      disabled={isSubmitting}
                      variant="contained"
                      type="submit"
                    >
                      Save details
                    </Button>
                  </CardActions>
                </form>
              )}
            </Formik>
          </TabPanel>
   </TabContext>

    <SnackBar
          open={open}
          message={message}
          severity={severity}
          handleClose={handleClose}
        />
    </Card>
  );
};

export default AccountDetails;
