import { combineReducers } from "@reduxjs/toolkit";
import { reducer as exampleReducer } from "./../redux/slices/example";
import { reducer as applicationReducer } from "./../redux/slices/applications";
import { reducer as notificationReducer } from './../redux/slices/notifications';
import { reducer as offerReducer } from './../redux/slices/offers';
import { reducer as snackbarReducer } from './slices/snackbar';
import {reducer as userReducer} from "./slices/usersSlice"
import {reducer as sectionReducer} from "./slices/sectionSlice"
import {reducer as subjectReducer} from "./slices/subjectsSlice"
import {reducer as levelReducer} from "./slices/levelSlice"
import {reducer as statisticsReducer} from "./slices/statistics"


const combinedReducer = combineReducers({
    example: exampleReducer,
    snackbar: snackbarReducer,
    notification: notificationReducer,
    users: userReducer,
    applications: applicationReducer,
    offers: offerReducer,
    sections:sectionReducer,
    subjects:subjectReducer,
    levels:levelReducer,
    stats:statisticsReducer,
});

const rootReducer = (state, action) => {
    if (action.type === "LOGOUT") {
        state = undefined;
    }
    return combinedReducer(state, action);
};
export default rootReducer;
