import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "./../../utils/axios";
import {openSnackBar} from "./snackbar"
const initialState = {
  examples: [],
  example: "",
  status: "idle",
  item: [],
  meta:[],
  error: null,
};

export const fetchData = createAsyncThunk(
  "example/fetchData",
  async (page) => {
    let data;
    try {
      const response = await axios.get(`/url/${page}`);
      data = await response.data;
      if ((response.status = 200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const getExample = createAsyncThunk(
  "example/getExample",
  async (exampleID, thunkAPI) => {
    let data;
    try {
      const response = await axios.get(`/url/${exampleID}`);
      data = await response.data;
      if ((response.status = 200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const updateExample = createAsyncThunk(
  "example/updateExample",
  async (exampleId, thunkAPI) => {
    let data;
    try {
      const response = await axios.put(`/url/${exampleId}`, {});
      data = await response.data;
      
      if ((response.status = 200)) {
        thunkAPI.dispatch(fetchData(""));
        thunkAPI.dispatch(openSnackBar({status:"success",msg:"item updated successfully"}))
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      thunkAPI.dispatch(openSnackBar({status:"error",msg:err.message}))
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const deleteExample = createAsyncThunk(
  "example/deleteExample",
  async (exampleId, thunkAPI) => {
    let data;
    try {
      const response = await axios.delete(`/url/${exampleId}`);
      data = await response.data;

      if ((response.status = 200)) {
        thunkAPI.dispatch(fetchData(""));
        thunkAPI.dispatch(openSnackBar({status:"success",msg:"item deleted successfully"}))

        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      thunkAPI.dispatch(openSnackBar({status:"error",msg:err.message}))
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const createExample = createAsyncThunk(
  "example/createExample",
  async (example, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(`/url/examples`, example);
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(fetchData(""));
        thunkAPI.dispatch(openSnackBar({status:"success",msg:"item created successfully"}))

        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      thunkAPI.dispatch(openSnackBar({status:"error",msg:err.message}))
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: "examples",
  initialState,
  reducers: {
    getItem: (state, action) => {
      state.item = action.payload;
    },
  },
  extraReducers: {
    [fetchData.pending]: (state) => {
      state.fetchstatus = "loading";
    },
    [fetchData.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.examples = action.payload.data;
      state.meta=action.payload.data.meta
    },
    [fetchData.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [updateExample.pending]: (state) => {
      state.status = "loading";
    },
    [updateExample.fulfilled]: (state, action) => {
      state.status = "succeeded";
    },
    [updateExample.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [getExample.pending]: (state) => {
      state.status = "loading";
    },
    [getExample.fulfilled]: (state, action) => {
      state.status = action.payload.data;
    },
    [getExample.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [deleteExample.pending]: (state) => {
      state.status = "loading";
    },
    [deleteExample.fulfilled]: (state, action) => {
      state.deletestatus = "succeeded";
      state.examples.filter((item) => item._id !== action.payload.data._id);
    },
    [deleteExample.rejected]: (state, action) => {
      state.deletestatus = "failed";
      state.error = action.payload;
    },

    [createExample.pending]: (state) => {
      state.status = "loading";
    },
    [createExample.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.examples.push(action.payload.data);
    },
    [createExample.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
  },
});
export const reducer = slice.reducer;
export const { getItem } = slice.actions;

export default slice;
