import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import BooleanFilter from "./Basic/BooleanFilter";
import TextFilter from "./Basic/TextFilter";
import DropdownFilter from "./Basic/DropdownFilter";
import DivisionAutocompleteFilter from "./Autocomplete/DivisionAutocompleteFilter";
import DateFilter from "./Basic/DateFilter";
import DateRangeFilter from "./Basic/DateRangeFilter";
import NumberFilter from "./Basic/NumberFilter";
import mapStateToRequest from "../../helpers/mapStateToRequest";
import DateTimeFilter from "./Basic/DateTimeFilter";
import {
  fetchApplications,
  saveFilteredValuesApplications,
} from "redux/slices/applications";
import { useDispatch } from "react-redux";
import { fetchOffers, saveFilteredValuesOffers } from "redux/slices/offers";
import { fetchAllUsers ,saveFilteredValues ,changeSectionLevels , saveSectionSelected} from "redux/slices/usersSlice";
import { fetchAllSections } from "redux/slices/sectionSlice";
import { fetchAllSubjects } from "redux/slices/subjectsSlice";
import { fetchAllLevels } from "redux/slices/levelSlice";
import {saveFilteredValuesLevel} from "redux/slices/levelSlice"
import {saveFilteredValuesSections} from "redux/slices/sectionSlice"
import {saveFilteredValuesSubjects} from "redux/slices/subjectsSlice"
const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  spacer: {
    flexGrow: 1,
  },
  card: {},
  cardContent: {
    paddingBottom: "16px !important",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  autocomplete: {
    marginTop: theme.spacing(1),
  },
  textField: {
    minWidth: "100%",
  },
  resetButton: {
    marginLeft: theme.spacing(1),
  },
}));

const Filter = (props) => {
  const {
    className,
    filtersChange,
    fields,
    values,
    pageLink,
    initializeData,
    rowsPerPage,
    FilterType,
    limit,
    page,
    search,
    sections,
    ...rest
  } = props;
  const classes = useStyles();
  const [filterValues, setFilterValues] = useState({});
  const dispatch = useDispatch()
  
  //MY CODE
  const [submited,setSubmited]=useState(false)
  const [reseted,setReseted]=useState(false)
  let sectionId
  let levelId


  useEffect(()=>{
    if (Object.keys(filterValues).length > 0 ){
      if (submited ){
        filterValues.page=page
        filterValues.limit=rowsPerPage
        filterValues.search=search
          if(FilterType === "users") {
            dispatch(fetchAllUsers(filterValues))
          }
          if(FilterType === "sections"){
            dispatch(fetchAllSections(filterValues))
          }
          if(FilterType === "levels"   ){
            dispatch(fetchAllLevels(filterValues))
          }
          if(FilterType === "subjects"   ){
            dispatch(fetchAllSubjects(filterValues))
          }
          if(FilterType === "offers") {
            dispatch(fetchOffers(filterValues));
          }
          if(FilterType === "applications") {
            dispatch(fetchApplications(filterValues));
          }
      }
    }
    if (reseted){
      if (FilterType==="users"){
        dispatch(fetchAllUsers({page:1,limit:rowsPerPage,search:search}))
        dispatch(saveFilteredValues(null))
      }
      if (FilterType==="sections"){
        dispatch(fetchAllSections({page:1,limit:rowsPerPage,search:search}))
        dispatch(saveFilteredValuesSections(null))
      }
      if (FilterType==="levels"){
        dispatch(fetchAllLevels({page:1,limit:rowsPerPage,search:search}))
        dispatch(saveFilteredValuesLevel(null))
      }
      if (FilterType==="subjects"){
        dispatch(fetchAllSubjects({page:1,limit:rowsPerPage,search:search}))
        dispatch(saveFilteredValuesSubjects(null))
      }
      if (FilterType === "offers") {
        dispatch(fetchOffers({ page: 1, limit: rowsPerPage, search: search }));
        dispatch(saveFilteredValuesOffers(null));
      }
      if (FilterType === "applications") {
        dispatch(
          fetchApplications({ page: 1, limit: rowsPerPage, search: search })
        );
        dispatch(saveFilteredValuesApplications(null));
      }
    }
  },[dispatch,FilterType,sectionId,levelId,filterValues,submited,reseted,page,search,rowsPerPage])

  useEffect(()=>{
      if (filterValues && (Object.keys(filterValues).includes("section")) &&sections){
        let levels
        sections.map((section)=> section._id===filterValues.section && Object.keys(section).length > 0 ? levels=section.levels :"")  
        dispatch(changeSectionLevels(levels))
        dispatch(saveSectionSelected(true))
        if (Object.keys(filterValues).includes("level")){
          let levelsId=[]
          levels.filter((level)=>levelsId.push(level._id))
            if (!levelsId.includes(filterValues.level)){
              delete filterValues.level
            } 
        }
      } 
   },[dispatch,sections,filterValues])


  const filterValuesChange = (value) => {
    setFilterValues({
      ...filterValues,
      ...value,
    });
  };

  const submitFilter = () => {
    let ignoreField = [];
    for (const v in filterValues) {
      if (filterValues[v]?.length === 2) {
        ignoreField.push(v);
      }
    }
    filtersChange(mapStateToRequest(filterValues, ignoreField));
    setSubmited(true)
    setReseted(false)
  };
  const resetFilter = () => {
    setFilterValues({});
    setSubmited(false)
    setReseted(true)
    initializeData(0,rowsPerPage);
    dispatch(changeSectionLevels())
    dispatch(saveSectionSelected(false))
  };

  const renderType = (field) => {
    switch (field.type) {
      case "boolean":
        return (
          <BooleanFilter
            values={filterValues}
            filtersChange={filterValuesChange}
            field={field}
          />
        );
      case "text":
        return (
          <TextFilter
            values={filterValues}
            filtersChange={filterValuesChange}
            field={field}
          />
        );
      case "number":
        return (
          <NumberFilter
            values={filterValues}
            filtersChange={filterValuesChange}
            field={field}
          />
        );
      case "dropdown":
        return (
          <DropdownFilter
            values={filterValues}
            filtersChange={filterValuesChange}
            field={field}
          />
        );
      case "date":
        return (
          <DateFilter
            values={filterValues}
            filtersChange={filterValuesChange}
            field={field}
          />
        );
      case "dateTime":
        return (
          <DateTimeFilter
            values={filterValues}
            filtersChange={filterValuesChange}
            field={field}
          />
        );
      case "dateRange":
        return (
          <DateRangeFilter
            values={filterValues}
            filtersChange={filterValuesChange}
            field={field}
          />
        );
      case "divisionAutocomplete":
        return (
          <DivisionAutocompleteFilter
            values={filterValues}
            filtersChange={filterValuesChange}
            field={field}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Grid container spacing={2} alignItems="center">
            {fields.map((f) => (
              <Grid item xs={12} md={3} key={f.name}>
                {renderType(f)}
              </Grid>
            ))}
            <Grid item xs={6} md={"auto"}>
              <Button
                variant="contained"
                color="primary"
                onClick={submitFilter}
              >
                Filter
              </Button>
              <Button
                className={classes.resetButton}
                variant="contained"
                onClick={resetFilter}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

Filter.propTypes = {
  className: PropTypes.string,
  filtersChange: PropTypes.func,
  initializeData: PropTypes.func,
  fields: PropTypes.array,
  values: PropTypes.object,
};

export default Filter;
