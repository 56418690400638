import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import Toolbar from '../../components/Toolbar/Toolbar';
import Table from '../../components/Table/Table';
import Collapse from '@material-ui/core/Collapse';
import Columns from '../../components/Columns';
import Filter from '../../components/Filter/Filter';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { fetchAllUsers, removeSelectedUserFromStore, saveFilteredValues } from 'redux/slices/usersSlice';
import SnackBar from 'components/SnackBar';
import Loading from 'components/Loading/Loading';
import { fetchAllSections } from 'redux/slices/sectionSlice';
import {
  changeStatusDeletedUser
} from "redux/slices/usersSlice";


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

const columnsList = [
  { accessor: '_id', name: '_id', label: 'Id', width: '7%', show: true, type: 'userSwitch' },
  { accessor: 'name', name: 'name', label: 'Name ', width: '10%', show: true, },
  { accessor: 'lastName', name: 'lastName', label: 'Last Name', width: '20%', show: true, },
  { accessor: 'phoneNumber', label: 'PhoneNumber', width: '20%', show: true, type: 'fieldValue' },
  { accessor: 'email', label: 'Email', width: '20%', show: true, type: 'fieldValue' },
  { accessor: 'gender', name: 'gender', label: 'Gender', width: '20%', show: true, type: 'fieldValue' },
  { accessor: 'role', label: 'Role', color: 'Primary', width: '20%', color: 'primary', show: true, type: 'fieldValue' },
  { accessor: 'active', name: 'active', label: 'Active', width: '30%', show: true, type: 'boolean' },
  { accessor: 'createdAt', name: 'createdAt', label: 'Created At', width: '20%', show: true, type: 'date' },
  { accessor: 'section.name', name: 'section', label: 'Section ', width: '20%', show: true, type: 'fieldValue' },
  { accessor: 'level.name', name: 'level', label: 'Level ', width: '20%', show: true, type: 'fieldValue' },
];

const fieldSearchable = [
  'id',
];

let fieldFilterable = [
  { name: 'gender', label: 'Gender', type: 'dropdown', options: [] },
  { name: 'role', label: 'Role', type: 'dropdown', options: [] },
  { name: 'createdAt', label: 'Created At', type: 'dateRange' },
  { name: 'active', label: 'Active', type: 'boolean' },
  { name: 'section', label: 'Section', type: 'dropdown', options: [] },
  { name: 'level', label: 'Level', type: 'dropdown', options: [] },
];

const UserList = () => {
  const dispatch = useDispatch()
  const [search, searchChange] = useState('');
  const [filters, filtersChange] = useState({});
  const [columns, columnsChange] = useState(columnsList);
  const [sort, sortChange] = useState({ accessor: 'id', order: 'desc' });
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [columnsOpen, setColumnsOpen] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [message, setAlertMessage] = useState("");
  const [severity, setAlertSeverity] = useState("");
  const [open, setOpen] = useState(false);
  const statusDeleteUser = useSelector((state) => state?.users?.statusDeletedUser)
  const {sectionLevels,filteredValue,SelectedSection}=useSelector((state)=>state?.users)

  //REFACTORING DEGREE && SECTION
  useEffect(()=>{
    dispatch(fetchAllSections({limit:"total"}))
  },[dispatch])
  
  const roles=[{"_id":"user","name":"user"},{"_id":"admin","name":"admin"}]
  const genders=[{"_id":"male","name":"male"},{"_id":"female","name":"female"}]
  const {sections}=useSelector((state)=> state?.sections)
 
  const addOptionsInField=(fieldName,fieldOptions)=>{
    if (fieldOptions){
    fieldFilterable.filter((field)=>field.name===fieldName?field.options=fieldOptions:"")
    }
  }
  addOptionsInField("role",roles)
  addOptionsInField("gender",genders)

  if (sections){
    fieldFilterable.filter((field)=>field.name==="section"?field.options=sections:"")
  }
  if (SelectedSection===false){
    fieldFilterable.filter((field)=>field.name==="level"?field.options=[]:"")
  }
  if (SelectedSection && typeof sectionLevels==="object"){
    fieldFilterable.filter((field)=>field.name==="level"?field.options=sectionLevels:"")
  }

  useEffect(()=>{
    if(filteredValue===null){
      filtersChange({})
      dispatch(saveFilteredValues([]))
    }
  },[filteredValue,filters])

  //first render: no search / no filter
  useEffect(() => {
    if (search.length<1 && Object.keys(filters).length < 1 ){
      dispatch(fetchAllUsers({page:page+1, limit:rowsPerPage}))
    }
  }, [dispatch,page,search,rowsPerPage]);
  
  //search render : no filter 
  useEffect(() => {
    if (search.length > 0 && Object.keys(filters).length< 1){
      const timeOut = setTimeout(() => {
        dispatch(fetchAllUsers({page:page+1, limit:rowsPerPage,search:search}))
      } , 300);
      return () => {
        clearTimeout(timeOut);
      };
    }
  }, [dispatch,page,search,rowsPerPage]);

  const {meta,users} = useSelector((state) => state?.users)

  useEffect(()=>{
    setData(users)
  },[users])

  useEffect(()=>{
    if(search.length > 0){
      setPage(0)
    }
  },[search])

  useEffect(()=>{
    if (Object.keys(filters).length > 0){
      setPage(0)
    }
  },[filters])

  const toggleFilters = () => {
    setFiltersOpen(!filtersOpen);
  };

  const toggleColumns = () => {
    setColumnsOpen(!columnsOpen);
  };

  const handleSortChange = (accessor) => {
    sortChange({
      accessor: accessor,
      order: sort.order === 'asc' ? 'desc' : 'asc',
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSnackBar = (type, msg) => {
    setOpen(true)
    setAlertSeverity(type)
    setAlertMessage(msg)
  }
  
  const handleClose = () => {
    setOpen(false);
  };
  const classes = useStyles();

  useEffect(() => {
    if (statusDeleteUser === "succeeded") {
      handleSnackBar("success", "User Deleted Successfully")
      dispatch(changeStatusDeletedUser())
    }
  }, [statusDeleteUser])

  useEffect(()=>{
     dispatch(removeSelectedUserFromStore())
  },[])

  if (!users) {
    return (<Loading/>);
  }

  const initializeData=(num,rows)=>{
    setPage(num)
    setRowsPerPage(rows)
  }

  return (
    <div className={classes.root}>
      <Toolbar
        toggleFilters={toggleFilters} toggleColumns={toggleColumns}
        searchChange={searchChange}
        pageLink={'/users/students'}
        searchMessage={'Search(Name,LastName,Email)'}
      />
      <div className={classes.content}>
        <Collapse in={columnsOpen}>
          <Columns columnsChange={columnsChange} columns={columns} />
        </Collapse>
        <Collapse in={filtersOpen}>
          <Filter FilterType={"users"} 
          initializeData={initializeData} 
          sections={sections} search={search} 
          fields={fieldFilterable} 
          values={filters} 
          rowsPerPage={rowsPerPage}
          filtersChange={filtersChange}
          page={page+1}
       />
        </Collapse>
        <Table columns={columns}
          data={data}
          handleSortChange={handleSortChange}
          sort={sort}
          meta={meta}
          handlePageChange={handleChangePage}
          handleRowsPerPageChange={handleChangeRowsPerPage}
          page={page}
          tableService={'user'}
          rowsPerPage={rowsPerPage}
          pageLink={'/users/students'}
        />
        <SnackBar
          open={open}
          message={message}
          severity={severity}
          handleClose={handleClose}
        />
      </div>
    </div>
  );
};

export default UserList;
