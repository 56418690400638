// @flow
import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
  LinearProgress,
} from "@material-ui/core";
import InsertChartIcon from "@material-ui/icons/InsertChartOutlined";
import GirlIcon from "@mui/icons-material/Girl";
import ManIcon from "@mui/icons-material/Man";
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  content: {
    alignItems: "center",
    display: "flex",
  },
  title: {
    fontWeight: 700,
  },
  avatarMale: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    height: 56,
    width: 56,
  },
  avatarFemale: {
    color: theme.palette.primary.contrastText,
    height: 56,
    width: 56,
  },
  colorPrimary: {
    backgroundColor: "#ffe8e8",
  },
  barColorPrimary: {
    backgroundColor: "#ff9696",
  },
  icon: {
    height: 32,
    width: 32,
  },
  progress: {
    marginTop: theme.spacing(3),
  },
}));
type Props = {
  className: string,
};

const TasksProgress = (props: Props): React$Element<any> => {
  const { className,users, ...rest } = props;
  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <Grid container justify="right" spacing={2}>
          <Grid item lg={6} sm={6} xl={6} xs={12}>
            <Grid container justify="right" spacing={2}>
              <Grid item lg={2} sm={2} xl={2} xs={2}>
                <Avatar className={classes.avatarMale}>
                  <ManIcon className={classes.icon} />
                </Avatar>
              </Grid>
              <Grid item lg={7} sm={7} xl={7} xs={7}>
                <div>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    MALE
                  </Typography>
                  <Typography variant="h3">{users?.male?.percentage}%</Typography>
                </div>
              </Grid>
            </Grid>
            <LinearProgress
              className={classes.progress}
              value={users?.male?.percentage}
              variant="determinate"
            />
          </Grid>
          <Grid item lg={6} sm={6} xl={6} xs={12}>
            <Grid container justify="right" spacing={2}>
              <Grid item lg={2} sm={2} xl={2} xs={2}>
                <Avatar
                  className={classes.avatarFemale}
                  style={{ background: "#ff9696" }}
                >
                  <GirlIcon className={classes.icon} />
                </Avatar>
              </Grid>
              <Grid item lg={7} sm={7} xl={7} xs={7}>
                <div>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    FEMALE
                  </Typography>
                  <Typography variant="h3">{users?.female?.percentage}%</Typography>
                </div>
              </Grid>
            </Grid>
            <LinearProgress
              className={classes.progress}
              classes={{
                colorPrimary: classes.colorPrimary,
                barColorPrimary: classes.barColorPrimary,
              }}
              // style={{ background: "#ff9696", color: "#ff9696" }}
              value={users?.female?.percentage}
              variant="determinate"
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

TasksProgress.propTypes = {
  className: PropTypes.string,
};

export default TasksProgress;
